#adminPanelShowcase {
  height: 50vh;
  width: 100%;
  background: url(../../../assets/img/admin/bulb1.jpg) no-repeat center
    bottom/cover;
  position: relative;
  overflow: hidden;
  z-index: 101;
  clip-path: polygon(0% 0%, 100% 0%, 100% 73%, 60% 93%, 0% 70%);
}

#adminPanelShowcase::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;

  background: linear-gradient(
    to left,
    rgba(34, 65, 202, 0.38) 0,
    rgba(117, 55, 207, 0.065) 100%
  );
}

#bgClr {
  background-color: #1c2431;
}
#Bg {
  background-color: #161b21;
  background-color: #001214;
  background-color: #9cbfc3;
  background-color: #222a35;
  background-color: #1c2431;
  /* background: url(../../../assets/img/admin/forest.jpg); */
  width: 100vw;
  height: auto;
  min-height: 100vh;
  padding-top: 20rem;
  margin-top: -11rem;
  z-index: 100;
  position: relative;
}

#hexagonBody {
  /* background-color: red; */
  position: relative;
  grid-row: 2/3;
  grid-column: 2/3;
  padding-top: 2rem;
}
#innerTitle {
  width: 100%;
  height: 100%;
  grid-row: 1/2;
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #e8e9ed;
  font-family: 'Montserrat', sans-serif;
}

#innerTitle input {
  background-color: #1c2431;
  color: #e8e9ed;
  border: none;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  width: 100%;
}

#innerTitle input:focus {
  outline: none;
}

#hexagonP {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-rows: fit-content, fit-content;
  grid-template-columns: 1fr 5fr;
}
#hexagonTitle {
  padding-top: 2rem;
  z-index: 2;
  grid-row: 1/2;
  grid-column: 1/2;
}
#hexagonTitle h1 {
  color: #e8e9ed;
  font-family: 'Montserrat';
}

#hexagonTitle span {
  padding: 0.8rem;
  margin-left: 1rem;
  background-color: #293042;
  border-radius: 0.4rem;
}

#btnCtrl {
  width: 200px;
  min-height: 200px;
  background-color: transparent;
  /* position: absolute;
  top: 50%;
  left: 0; */
  margin-top: 10rem;
  grid-row: 2/3;
  grid-column: 1/2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 100px);
  gap: 0.5rem;
  z-index: 300;
}

#add,
#remove,
#favorite {
  background-color: #dee5e5;
}
#add {
  font-size: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: teal;
  cursor: pointer;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
#remove {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}
#remove i {
  color: teal;
  font-size: 2.5rem;
  transition: color 0.5s ease-in-out;
}
.innerPlus {
}
#favorite {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  cursor: pointer;
  background-color: #dee5e5;
  transition: background-color 0.5s ease-in-out;
}

#favorite i {
  color: teal;
  transition: color 0.5s ease-in-out;
}
#add i {
  transition: color 0.3s ease-in-out;
}
/* #remove:hover */

#remove:hover i {
  color: #dee5e5;
  color: #83020d;
  color: #293042;
}
#confirm {
  background-color: #293042;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  cursor: pointer;
}
#confirm i {
  color: lightseagreen;
}

#hexGrid {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  font-family: 'Nunito';
  list-style-type: none;
  /* background-color: #2c3546; */
}

.hex {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  transition: all 0.5s;
  backface-visibility: hidden;
  will-change: transform;
  transition: all 0.5s;
  cursor: pointer;
}
.hex::after {
  content: '';
  display: block;
  padding-bottom: 86.602%; /* =  100 / tan(60) * 1.5 */
}
.hexIn {
  position: absolute;
  width: 96%;
  padding-bottom: 110.851%; /* =  width / sin(60) */
  margin: 2%;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transition: all 0.5s;
}
.hexIn * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
}
.hexLink {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  -ms-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  text-align: center;
}

/* .hex:nth-of-type(1) .hexImg {
  background-image: url(../../../assets/img/admin/bulb1.jpg);
  background-image: url('https://images.unsplash.com/44/MIbCzcvxQdahamZSNQ26_12082014-IMG_3526.jpg?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;w=1080&amp;fit=max&amp;s=49dab7a5e4b2e28b5707bc2db974c94b');
}
.hex:nth-of-type(2) .hexImg {
  background-image: url('https://images.unsplash.com/photo-1417436026361-a033044d901f?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;w=1080&amp;fit=max&amp;s=faa4e192f33e0d6b7ce0e54f15140e42');
}
.hex:nth-of-type(3) .hexImg {
  background-image: url('https://images.unsplash.com/44/MIbCzcvxQdahamZSNQ26_12082014-IMG_3526.jpg?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;w=1080&amp;fit=max&amp;s=49dab7a5e4b2e28b5707bc2db974c94b');
}
.hex:nth-of-type(4) .hexImg {
  background-image: url('https://images.unsplash.com/photo-1425668273332-3a46ab26b161?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;w=1080&amp;fit=max&amp;s=d453ab3dec298c43415526458b360fa6');
}
.hex:nth-of-type(5) .hexImg {
  background-image: url('https://images.unsplash.com/photo-1423145369430-a9ea0de096cd?crop=entropy&amp;fit=crop&amp;fm=jpg&amp;h=1375&amp;ixjsv=2.1.0&amp;ixlib=rb-0.3.5&amp;q=80&amp;w=1725');
}
.hex:nth-of-type(6) .hexImg {
  background-image: url('https://images.unsplash.com/photo-1432887382605-0abf9cc49e8f?crop=entropy&amp;fit=crop&amp;fm=jpg&amp;h=1375&amp;ixjsv=2.1.0&amp;ixlib=rb-0.3.5&amp;q=80&amp;w=1725');
}
.hex:nth-of-type(7) .hexImg {
  background-image: url('https://images.unsplash.com/uploads/14122811862445bc266cf/931448f2?crop=entropy&amp;fit=crop&amp;fm=jpg&amp;h=1375&amp;ixjsv=2.1.0&amp;ixlib=rb-0.3.5&amp;q=80&amp;w=1725');
}
.hex:nth-of-type(8) .hexImg {
  background-image: url('https://images.unsplash.com/photo-1417026846249-f31f7e43fc35?crop=entropy&amp;fit=crop&amp;fm=jpg&amp;h=1375&amp;ixjsv=2.1.0&amp;ixlib=rb-0.3.5&amp;q=80&amp;w=1725');
}
.hex:nth-of-type(9) .hexImg {
  background-image: url('https://images.unsplash.com/uploads/14122811862445bc266cf/931448f2?crop=entropy&amp;fit=crop&amp;fm=jpg&amp;h=1375&amp;ixjsv=2.1.0&amp;ixlib=rb-0.3.5&amp;q=80&amp;w=1725');
}
.hex:nth-of-type(10) .hexImg {
  background-image: url('https://images.unsplash.com/photo-1423145369430-a9ea0de096cd?crop=entropy&amp;fit=crop&amp;fm=jpg&amp;h=1375&amp;ixjsv=2.1.0&amp;ixlib=rb-0.3.5&amp;q=80&amp;w=1725');
}
.hex:nth-of-type(11) .hexImg {
  background-image: url('https://images.unsplash.com/44/MIbCzcvxQdahamZSNQ26_12082014-IMG_3526.jpg?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;w=1080&amp;fit=max&amp;s=49dab7a5e4b2e28b5707bc2db974c94b');
}
.hex:nth-of-type(12) .hexImg {
  background-image: url('https://images.unsplash.com/photo-1432887382605-0abf9cc49e8f?crop=entropy&amp;fit=crop&amp;fm=jpg&amp;h=1375&amp;ixjsv=2.1.0&amp;ixlib=rb-0.3.5&amp;q=80&amp;w=1725');
} */

.hex img {
  left: -100%;
  right: -100%;
  width: auto;
  height: 100%;
  margin: 0 auto;
}

.hex h1,
.hex p {
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
  font-weight: 300;
  opacity: 0;
}

.hexCellTitle {
  color: #f5ce95;
  text-transform: capitalize;
  text-align: center;
  /* bottom: 40%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-self: center;
  padding-top: 50%;
  font-size: 1.5em;
  z-index: 1;
  user-select: none;
}

.hexCellName {
  color: #f5ce95;
  text-transform: capitalize;
  text-align: center;
  bottom: 30%;
  /* padding-top: 50%; */
  width: 100%;
  opacity: 0;
  font-size: 1.5em;
  z-index: 1;
  user-select: none;
  font-weight: lighter;
}

.hexCellSubTitle {
  top: 50%;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
}

.hexImg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hexImg:before,
.hexImg:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  opacity: 0;
  transition: opacity 0.5s;
}
.hexImg:before {
  background: rgba(22, 103, 137, 0.3);
}
.hexImg:after {
  background: linear-gradient(
    to top,
    transparent,
    rgba(0, 0, 0, 0.5),
    transparent
  );
}

/*** HOVER EFFECT  **********************************************************************/

.hexLink:hover h1,
.hexLink:hover h2,
.hexLink:focus h1,
.hexLink:focus h2,
.hexLink:hover p,
.hexLink:focus p {
  opacity: 1;
  transition: 0.8s;
}

.hexIn:hover .hexImg:before,
.hexIn:hover .hexImg:after,
.hexIn:hover .hexLink {
  opacity: 1;
}

#hexOverlay {
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#hexOverlay i {
  color: #293042;
  font-size: 2.5rem;
  display: none;
}

/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
@media (min-width: 1201px) {
  /* <- 5-4  hexagons per row */
  #hexGrid {
    padding-bottom: 4.4%;
  }
  .hex {
    width: 20%; /* = 100 / 5 */
  }
  .hex:nth-child(9n + 6) {
    /* first hexagon of even rows */
    margin-left: 10%; /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 1200px) and (min-width: 901px) {
  /* <- 4-3  hexagons per row */
  #hexGrid {
    padding-bottom: 5.5%;
    font-size: 13px;
  }
  .hex {
    width: 25%; /* = 100 / 4 */
  }
  .hex:nth-child(7n + 5) {
    /* first hexagon of even rows */
    margin-left: 12.5%; /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 900px) and (min-width: 601px) {
  /* <- 3-2  hexagons per row */
  #hexGrid {
    padding-bottom: 7.4%;
    font-size: 14px;
  }
  .hex {
    width: 33.333%; /* = 100 / 3 */
  }
  .hex:nth-child(5n + 4) {
    /* first hexagon of even rows */
    margin-left: 16.666%; /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 600px) {
  /* <- 2-1  hexagons per row */
  #hexGrid {
    padding-bottom: 11.2%;
    font-size: 12px;
  }
  .hex {
    width: 50%; /* = 100 / 3 */
  }
  .hex:nth-child(3n + 3) {
    /* first hexagon of even rows */
    margin-left: 25%; /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 400px) {
  #hexGrid {
    font-size: 8px;
  }
}

/* create article */
form,
form label {
  font-family: 'Montserrat' !important;
}

#createArticle {
  opacity: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 5rem 7rem 5rem;
  transform: translate(-50%, -200%);
  z-index: -1;
  width: 40vw;
  height: fit-content;
  margin: 0 auto;
  background-color: #fff;
  overflow: hidden;
  transition: transform 0.4s ease-in, opacity 0.4s ease-in, z-index 0.4s ease-in;
}

#createArticle h2 {
  margin-bottom: 5rem;
  text-align: center;
  color: #4353a0;
}
#createArticle label {
  color: #4353a0;
}
#selectLabel {
  color: #4353a0;
}
#createArticle .nl {
  margin-bottom: -3rem;
  transition: margin-bottom 0.2s ease-in;
}
#createArticle input {
  border-top: none;
  border-radius: none;
  border-left: none;
  border-right: none;
  color: initial;
  background-color: transparent;
  border-bottom: 0.1rem solid rgb(204, 204, 204);
  box-shadow: none;
  border-radius: 0;
  padding-left: 0;
}

#createArticle input:focus {
  border-top: none;
  border-radius: none;
  border-left: none;
  border-color: transparent;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 0.1rem solid rgb(67, 83, 160);
}
#createArticle input:nth-of-type(2) {
  margin-bottom: 0.5rem;
}
#submitArticle {
  background-color: #4353a0 !important;
  color: #fff !important;
  width: 100%;
  border-radius: 0.4rem !important;
  margin-top: 3rem;
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
  border: 1px solid #ddd;
  padding: 1rem 0;
  font-family: 'Montserrat';
}
#submitArticle:hover {
  background-color: #5062bd !important;
}

#overlay,
#addWorkerOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 210;
  z-index: -1;
  /* display: none; */
}
#addWorkerOverlay {
  /* display: block; */
}

#addWorkerOverlay form {
  margin-top: 15rem !important;
  opacity: 0;
  transform: translateY(-100vh);
  width: 40%;
  transition: transform 0.4s ease-in, opacity 0.4s ease-in, z-index 0.4s ease-in;
}

#addWorkerOverlay form input[type='submit'] {
  width: 35%;
}

#showNoImg {
  display: none;
  border: 0.1rem solid red;
  width: fit-content;
  padding: 1rem;
  border-radius: 0.5rem;
  color: red;
}

#spinnerParent {
  display: block;
  width: 50%;
  height: 300px;
  background-color: transparent;
  z-index: 212;
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -10%);
  pointer-events: none;
}

#articleSpinner,
#articleSpinner:after {
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  z-index: 212;
}
#articleSpinner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1rem solid rgba(165, 16, 16, 0.2);
  border-right: 1.1rem solid rgba(165, 16, 16, 0.2);
  border-bottom: 1.1rem solid rgba(165, 16, 16, 0.2);
  border-left: 1.1rem solid #5062bd;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#articleUploaded {
  display: none;
  position: absolute;
  z-index: 12;
  width: fit-content;
  height: fit-content;
  color: green;
  font-size: 20px;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
  border: 0.1rem solid green;
  padding: 2rem;
  background-color: #fff;
  transition: opacity 0.4s ease-in;
}
#articleUploaded i {
  margin-right: 2rem;
  color: green;
}

#preLoad,
#preLoad:after {
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  z-index: 12;
}
#preLoad {
  margin: 60px auto;
  font-size: 10px;
  position: absolute;
  top: 0;
  left: 45%;
  text-indent: -9999em;
  border-top: 1.1rem solid rgba(80, 98, 189, 0.2);
  border-right: 1.1rem solid rgba(80, 98, 189, 0.2);
  border-bottom: 1.1rem solid rgba(80, 98, 189, 0.2);
  border-left: 1.1rem solid rgb(80, 98, 189);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

#overlaySlider {
  display: none;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.5);
}

#overlaySlider img {
  position: fixed;
  max-width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#imgOL {
  font-size: 70px;
  position: fixed;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: color 200ms ease-in-out;
}
#imgOR {
  font-size: 70px;
  position: fixed;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: color 200ms ease-in-out;
}

#imgOR:hover,
#imgOL:hover {
  color: royalblue;
}
@media only screen and (max-width: 500px) {
  #hexagonP {
    grid-template-columns: 1fr 2fr !important;
    grid-template-rows: 2fr auto;
  }
  #btnCtrl {
    grid-template-rows: repeat(2, 80px);
    margin-top: 5rem;
    width: initial;
  }
  #innerTitle input {
    font-size: 10px !important;
    height: 100%;
  }
  #createArticle {
    width: 90vw;
    padding: 5rem 4rem 5rem;
  }
}
@media only screen and (max-width: 900px) and (max-height: 500px) {
  #createArticle {
    width: 80vw;
    height: fit-content;
    top: 55%;
    padding: 1rem;
  }
  #createArticle h2 {
    margin-bottom: 0rem;
  }
  #submitArticle {
    margin: 0;
  }
}
