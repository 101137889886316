main {
  position: absolute;
  height: fit-content;
  width: 90%;
  background-color: #fff;
  z-index: -10;
  top: 100vh;
  left: 5%;
  margin: auto;
}
#item2 {
  width: 38vw;
  height: 90%;
  position: relative;
  overflow: hidden;
  border-top: 5px solid #536dfe;
}
#item3 {
  width: 50vw;
  height: 90%;
  position: relative;
  overflow: hidden;
  border-top: 5px solid #536dfe;
}
#whatwedo {
  width: 100%;
  height: 40vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* box-shadow: 0px 3px 6px rgba(149, 157, 165, 0.3); */
}

#bx1 {
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/kvservis.jpg") 50% 100% / contain;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  position: relative;
  box-shadow: 0px 3px 6px rgba(149, 157, 165, 0.3);
}
#bx1 h1 {
  position: absolute;
  top: 60%;
  left: 50%;
  text-transform: capitalize;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
}
#bx1::before,
#bx2::before,
#bx3::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
#bx4::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
#bx2 {
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/uticnicasmall.jpg") center / cover;
  background: url("../../../assets/img/electric-eng.jpg") center / cover
    no-repeat;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  position: relative;
}

#bx2 h1,
#bx3 h1,
#bx4 h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  text-align: center;
}

#bx3 {
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/uticnicasmall.jpg") center / cover;
  background: url("../../../assets/img/najntehn2.png") center / cover no-repeat;
  /* clip-path: circle(47.5% at 50% 50%); */
  border-radius: 50%;
  width: 90%;
  margin: auto;
  position: relative;
}
#bx3::before {
  clip-path: none;
  border-radius: 50%;
}
#bx4 {
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/uticnicasmall.jpg") center / cover;
  background: url("../../../assets/img/strucnitim2.png") center right / cover
    no-repeat;
  background: url("../../../assets/img/davidim2.svg") center center / contain
    no-repeat;
  background-color: #6b7c93;
  clip-path: polygon(
    30% 0%,
    70% 0%,
    100% 30%,
    100% 70%,
    70% 100%,
    30% 100%,
    0% 70%,
    0% 30%
  );
  position: relative;
}
main #spoji h2 {
  margin: 10rem 0 0;
  text-align: center;
  font-size: 4rem;
  color: #fcbf1e;
  position: relative;
  letter-spacing: 0.5rem;
  font-weight: 500;
}
#spoji img {
  transform: scale(0.6) translateY(-5rem);
}
main h2 strong {
  font-weight: 500;
  /* font-size: 10px; */
  width: 100vw;
}

main span {
  position: relative;
  border-bottom: 2px solid #fcbf1e;
  border-radius: 45%;
  font-weight: 600;
  position: absolute;
  color: #1b6ca8;
  top: -0.5rem;
  margin-left: 0.5rem;
  letter-spacing: 0rem;
}

main span::after {
  content: "";
  top: 0;
  right: 5px;
  width: 20px;
  height: 17px;
  transform: rotate(-10deg);
  border-radius: 50%;
  border-top: 5px solid #1b6ca8;
  position: absolute;
}
#spoji {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#spoji h2 {
  /* position: absolute;
  margin-left: -5vw;
  left: -3%; */
}

#maingif {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 7rem;
}

#mainimgcontainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  /* height: 56rem; */
}

.box {
  position: relative;
  max-width: 100%;
  height: 215px;
  background: #fff;
  margin: 30px 10px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
}
.box:nth-of-type(3) .content h2 {
  margin-bottom: 1rem !important;
}
.box:nth-of-type(2) .content h2 {
  margin-bottom: 3rem !important;
}
.box .imgBx {
  position: relative;
  width: 99%;
  min-height: 23rem;
  top: -8vh;
  margin: 0 auto;
  z-index: 1;

  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.box:first-child > .content h2,
.box:nth-of-type(2) > .content h2,
.box:nth-of-type(3) > .content h2,
.box:nth-of-type(4) > .content h2 {
  margin-bottom: 5rem;
}

.box:first-child > .imgBx {
  background: url("../../../assets/img/uticnicasmall.jpg") center / cover;
  border-radius: 4px;
}
.box:nth-child(2) > .imgBx {
  background: url("../../../assets/img/thumb1.png") center / cover;
  background-color: #1b6ca8;
  border-radius: 4px;
}
.box:nth-child(3) > .imgBx {
  background: url("../../../assets/img/smallthumb.jpg") center / cover;
  border-radius: 4px;
}
.box:nth-child(4) > .imgBx {
  background: url("../../../assets/img/strucantim.jpg") center / cover;
  border-radius: 4px;
}

.box:hover {
  height: 50rem;
}

.box .content {
  margin-top: -140px;
  padding: 10px 15px;
  max-width: 100%;
  text-align: center;
  color: #333;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.box:hover .content {
  visibility: visible;
  opacity: 1;
  margin-top: -40px;
  transition-delay: 0.3s;
}

.box .content h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-transform: capitalize;
}

.box .content p {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.6;
}

#mainimgcontainer {
  position: relative;
}

/* KAKO FUNKCIONIŠEMO SEKCIJA */

#proces header {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 10rem;
}

#uppertext img {
  width: 6rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -55%;
  line-height: 6rem;
}

#uppertext h2 {
  font-family: "Josefin Sans";
  font-size: 3rem;
  text-transform: uppercase;
  color: lightslategrey;
  border: 1px solid #fcbf1e;
  padding: 2rem 5rem;
}

#uppertext p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  line-height: 0;
  background-color: #fff;
  color: #1b6ca8;
  letter-spacing: 0.3rem;
  z-index: 1;
  height: 30px;
  width: 12rem;
  bottom: -2.9rem;
  text-align: center;
}

#gridparent {
  position: relative;
  margin-top: 15rem;
  background-color: #fff;
  width: 100%;
  height: 400px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

#gridparent .gbox .front {
  background-color: #212529;
}
#gridparent .gbox.boxtxt {
  background-color: #212529;
}
#linijax {
  position: absolute;
  width: 100%;
  top: 50%;
  height: 0.1rem;
  background-color: lightgreen;
}

#linijay1 {
  position: absolute;
  width: 0.15rem;
  height: 100%;
  background-color: lightgreen;
  left: 25%;
}

#linijay2 {
  position: absolute;
  width: 0.1rem;
  height: 100%;
  background-color: lightgreen;
  left: 50%;
}
#linijay3 {
  position: absolute;
  width: 0.1rem;
  height: 100%;
  background-color: lightgreen;
  left: 75%;
}

.gbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.gbox .front,
.gbox .back {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
#gridparent .gbox:nth-of-type(2) .back i {
  position: absolute;
  left: 50%;
  transform: translateX(-30%);
}
#gridparent .gbox:nth-of-type(1) .fa-mobile {
  position: absolute;
  right: 20%;
  bottom: 15%;
}
#gridparent .gbox:nth-of-type(1) .fa-desktop {
  position: absolute;
  left: 20%;
  top: 15%;
}

/* 
diagonal line through box
.l {
  width: 200px;
}
.xl {
  width: 300px;
}
svg {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateX(180deg);
} */
i {
  color: #fff;
}
.back i {
  color: #1b6ca8;
}
.boxtxt {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.front:hover .boxtxt p {
  color: #1b6ca8;
}
.boxtxt h2 {
  font-size: 2rem;
  padding-bottom: 2rem;
  color: lightgreen;
}

.boxtxt p {
  text-align: center;
  color: #fff;
  font-size: 1.4rem;
}

.gbox div.back {
  width: 100%;
  height: 100%;
  transform: rotateY(90deg);
}

.gbox div.front {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
}

/* FOOTER */

#footer {
  margin: 30rem 0 0;
  display: grid;
  margin-left: -5vw;
  grid-template-rows: repeat(1fr, fit-content);
  width: 100vw;
  background-color: #212529;
  height: fit-content;
  padding-top: 7rem;
  position: relative;
}

#footer #bottomrow p {
  padding: 2rem 0;
  text-align: center;
  font-size: 1.4rem;
  color: #fff;
}

#upperrow {
  grid-row: 1/3;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

#footersct1 {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-end;
}

#footersct1 h2 {
  font-size: 2.8rem;
  color: #f6d54d;
  cursor: pointer;
  font-weight: 500;
}

#footersct1 img {
  width: 6rem;
  height: 6rem;
}
#fix {
  position: absolute;
  color: #1b6ca8;
  font-size: 2.8rem;
  border-bottom: none;
  text-transform: capitalize;
}

#fix::after {
  content: "";
  display: none;
}

#footersct2 h2 {
  color: wheat;
}

#footersct2 p {
  margin-top: 2rem;
  color: wheat;
  font-size: 1.4rem;
}

#footersct2 #btnfooter {
  text-decoration: none;
  color: wheat;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  transition: padding 0.5s ease;
  margin-left: 2rem;
  margin: auto;
  opacity: 0;
}
#footersct2 strong a {
  text-decoration: none;
  color: #fff;
}
#hovanim {
  position: relative;
  background-color: transparent;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
}

#hovanim #fillbtn {
  position: absolute;
  bottom: -0.1rem;
  width: 70%;
  margin: 0 auto;
  height: 0.2rem;
  background-color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
#hovanim2 {
  position: relative;
  background-color: transparent;
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  padding: 0 2rem;
}
#hovanim2 #ka {
  position: absolute;
  bottom: -0.1rem;
  width: 70%;
  margin: 0 auto;
  height: 0.2rem;
  background-color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

#fillbtn strong {
  position: absolute;
  bottom: 1rem;
}
#ka strong {
  position: absolute;
  bottom: 1rem;
}
#hovanim:hover #fillbtn strong a {
  transition: color 0.5s ease;
  color: #1b6ca8;
}

#ka {
}

@keyframes fillbtn {
  40% {
    width: 80%;
  }
  50% {
    height: 50%;
    width: 90%;
  }
  60% {
    height: 50%;
    width: 100%;
  }
  70% {
    height: 70%;
    width: 100%;
  }
  85% {
    height: 90%;
    width: 100%;
  }
  100% {
    height: 100%;
    width: 100%;
  }
}

@keyframes fillbtnrev {
  0% {
    width: 100%;
    height: 100%;
  }
  10% {
    width: 90%;
    height: 90%;
  }
  20% {
    width: 80%;
    height: 80%;
  }
  30% {
    width: 70%;
    height: 70%;
  }
  50% {
    height: 50%;
    width: 70%;
  }
  60% {
    height: 40%;
    width: 70%;
  }
  70% {
    height: 30%;
    width: 70%;
  }
  85% {
    height: 15%;
    width: 70%;
  }
  100% {
    height: 0.2rem;
    width: 70%;
  }
}

#footersct3 {
  padding-left: 2rem;
  margin-left: 8rem;
}
#footersct3 h2 {
  color: wheat;
  margin-bottom: 2rem;
}
#footersct3 ul li {
  list-style-type: none;
  padding-bottom: 1rem;
}
#footersct3 ul li a {
  text-decoration: none;
  color: wheat;
  font-size: 1.4rem;
}

#footersct3 ul li a:hover {
  color: white;
}

#footersct4 h2 {
  color: wheat;
  margin-bottom: 2rem;
}

#footersct4 ul li {
  list-style-type: none;
  padding-bottom: 1rem;
  color: wheat;
  font-size: 1.4rem;
}

#totop {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  background-color: #536dfe;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}
#totop:hover {
  transform: translateY(-1rem);
  transform: translate(-50%, -60%);
}

#totop:hover i {
  animation: totop 0.7s ease forwards infinite;
}

@keyframes totop {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

#usluge {
  margin-top: 10rem;
  width: 100%;
  height: auto;
  /* background: rgb(228, 4, 211);
  background: linear-gradient(
    310deg,
    rgba(228, 4, 211, 1) 0%,
    rgba(79, 16, 240, 1) 96%
  ); */
}

/* SECTION USLUGE */

#usluge header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#usluge header h2 {
  font-size: 3rem;
  font-family: "Raleway";
  text-transform: uppercase;
  color: lightslategray;
  letter-spacing: 0.1rem;
}

#usluge header h3 {
  font-weight: 400;
  font-style: italic;
  font-size: 1.8rem;
  margin-top: 0.5rem;
  color: #666;
  font-family: "Playfair Display", Arial, Helvetica, sans-serif;
  position: relative;
}

.lineh {
  margin-top: 1rem;
  width: 0.1rem;
  height: 5rem;
  background-color: #ccc;
}

.linec {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 0.2rem solid #70bad2;
}

#uslugebody {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 5rem;
  padding-top: 5rem;
  border-top: 1px solid rgba(83, 109, 254, 0.1);
  margin-top: 2rem;
}

#uslugebody .usluge {
  display: grid;
  grid-template-rows: auto auto 1fr;
  justify-items: center;
  text-align: center;
  position: relative;
}
#usluge header h2 {
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
}
#uslugebody .usluge .imgwrapper {
  width: 150px;
  height: 150px;
  background-color: #eff2f7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eff2f7;
  position: relative;
  margin-bottom: 2.5rem;
}

#uslugebody .usluge .imgwrapper::before {
  content: "";
  position: absolute;
  width: 120%;
  height: 120%;
  background-color: transparent;
  border-radius: 50%;
  overflow-x: hidden;
  border: 2px solid #eff2f7;
}

#uslugebody .usluge h3 {
  margin-top: 2rem;
  font-size: 2.5rem;
  color: #333;
  color: #555;
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  position: relative;
}

#uslugebody .usluge h3::before {
  position: absolute;
  content: "";
  width: 2.5rem;
  height: 0.2rem;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #536dfe;
}
#uslugebody .usluge p {
  margin-top: 2rem;
  font-size: 1.3rem;
  line-height: 2.6rem;
  font-family: "Raleway";
  color: #333;
  text-align: left;
}
#uslugebody .usluge img {
  position: absolute;
  transform: translateY(-0.5rem);
  background-color: #eff2f7;
  width: 100px;
}
.usluge i {
  color: rgba(173, 216, 230, 0.4);
  color: rgba(173, 216, 230, 1);
}
/* .imgwrapper .fa-house-damage {
  color: rgba(112, 186, 210, 0.7);
}

.fa-lightbulb,
.fa-laptop-house {
  color: rgba(112, 186, 210, 0.7);
} */

.sr {
  margin-top: 2rem;
}

#fixaj {
  margin-top: 2.5rem;
}
#fixaj li {
  list-style: none;
}

#fixaj p {
  margin-top: 0 !important;
  text-align: left !important;
}

/* LOOK AROUND SECTION */

#lookaround {
  margin-top: 10rem;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: url("../../../assets/img/installationsidebar.jpg") center/cover;
}

#lookaroundimg {
  background: url("/img/installationsidebar.jpg") no-repeat / cover;
}

#lookaroundtxt {
  padding: 1rem 2rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: 1.4rem;

  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

#lookaroundtxt h3 {
  margin-bottom: 2rem;
}
#lookaroundtxt p {
  line-height: 2.5rem;
}
#lookaroundtxt p:last-of-type {
  margin-top: 1rem;
}

#lookaroundtxt a {
  margin-top: 2rem;
  color: #fff;
  text-decoration: none;
}

/* PROJECTS */

#projects {
  margin-top: 10vh;
  width: 100vw !important;
  height: 100vh;
  margin-left: -5vw;
  position: relative;
}

#projects::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/showcaseknx1.jpg") no-repeat top center /
    cover;
  z-index: -1;
}
#projects::before {
  content: "";
  position: absolute;
  top: -10%;
  left: 0;
  width: 100%;
  height: 15%;
  background: #fff;
  z-index: 2;
  transform: skewY(-2deg);
}
/* #projects::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
} */
/* #box1 {
  width: 500px;
  height: 500px;
  background-color: red;
  position: relative;
  top: 100%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 999;
} */
#projects #project {
  position: relative;
  max-width: 77vw;
  height: 35rem;
  padding: 0 auto;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* bottom: calc(0px - (35rem / 2)); */

  top: 75%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  /* background-color: red; */
}

#projects #prev {
  position: absolute;
  bottom: 0%;
  left: 5%;
  transform: translateY(50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;

  border-radius: 4px;
  padding: 2rem 1rem;
  transition: background-color 0.4s ease;
}
#projects #next {
  cursor: pointer;

  position: absolute;
  bottom: 0%;
  right: 5%;
  transform: translateY(50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 2rem 1rem;
  transition: background-color 0.4s ease;
}

#projects #prev i {
  color: #fff;
}
#projects #next i {
  color: #fff;
}

.fa-facebook-square {
  color: #3b579d;
  transform: scale(1.5);
  padding-right: 0.5rem;
}

#resizeGmail {
  height: 2rem;
  transform: translateX(-0.4rem);
}

#projects #project #projectslider {
  display: grid;
  /* grid-template-columns: repeat(6, 1fr); */
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  position: relative;
  /* padding-left: 1.7vw; */
  transform: translateX(0vw);
  transition: transform 0.5s ease;
}

#projects #project #projectslider #one {
  width: 25vw;
  height: 90%;
  /* background: url("../../../assets/img/jakastruja.jpg") no-repeat center/cover; */
  position: relative;
  border-top: 5px solid #536dfe;
  left: 0;
}
.project {
  /* height: 90%;
  background-color: red;
  width: 25vw;
  position: relative;
  border-top: 5px solid #536dfe; */
}
#one {
  left: 0;
}
.project h3 {
  position: absolute;
  font-size: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-transform: uppercase;
}
#projects #project #one::before,
#projects #project #item2::before,
#projects #project #item3::before {
  transition: background-color 0.5s ease;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
#projects #project #one:hover::before,
#projects #project #item2:hover::before,
#projects #project #two:hover::before,
#projects #project #three:hover::before,
#projects #project #four:hover::before,
#projects #project #five:hover::before,
#projects #project #six:hover::before {
  background-color: rgba(0, 0, 0, 0);
}
#projects #project #two::before {
  transition: background-color 0.5s ease;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
#projects #project #three::before,
#projects #project #four::before,
#projects #project #five::before,
#projects #project #six::before {
  transition: background-color 0.5s ease;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
#projects #project #projectslider #two {
  position: relative;
  width: 24vw;
  height: 90%;
  margin-left: 0;
  background-color: red;
  background: url("../../../assets/img/showcaseknx1.jpg") no-repeat center/cover;
  border-top: 5px solid #536dfe;
}

#projects #project #projectslider #three {
  position: relative;
  width: 24vw;
  height: 90%;
  background-color: red;
  background: url("../../../assets/img/installationsidebar.jpg") no-repeat
    center/cover;
  border-top: 5px solid #536dfe;
}
#projects #project #projectslider #four {
  position: relative;
  width: 25vw;
  height: 90%;
  background-color: red;
  background: url("../../../assets/img/probaj2.jpg") no-repeat center/cover;
  border-top: 5px solid #536dfe;
}
#projects #project #projectslider #five {
  position: relative;
  width: 25vw;
  height: 90%;
  background-color: red;
  background: url("../../../assets/img/probaj.jpg") no-repeat center/cover;
  border-top: 5px solid #536dfe;
}
#projects #project #projectslider #six {
  position: relative;
  width: 25vw;
  height: 90%;
  background-color: red;
  background: url("../../../assets/img/probaj1.jpg") no-repeat center/cover;
  border-top: 5px solid #536dfe;
}

/* PROJECTSHEADER TXT */

#projectsheader {
  text-align: center;
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#projectsheader h2 {
  position: relative;
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  width: fit-content;
  margin: auto;
  color: #333;
  color: #555;
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  border-bottom: 2px solid #536dfe;
  margin-bottom: 1rem;
}
#projectsheader h2::before {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 0.2rem;
  background-color: #536dfe;
}
#mobilemenu {
  display: none;
}
.box:nth-of-type(4) > .content h2 {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1250px) {
  #whatwedo {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 50vh;
    gap: 5rem;
    justify-content: center;
    align-items: center;
  }

  #whatwedo #bx1,
  #whatwedo #bx2,
  #whatwedo #bx3,
  #whatwedo #bx4 {
    /* padding: 5rem; */
    width: 80%;
    margin: auto;
  }
  #whatwedo #bx1 h1 {
    text-align: center;
  }
  #whatwedo #bx3 {
    /* height: 80%; */
    width: 70%;
    clip-path: none;
    border-radius: 50%;
  }
  #whatwedo #bx3::before {
    border-radius: 50%;
  }
}

@media only screen and (max-width: 850px) {
  #whatwedo {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    height: 150vh;
    width: 60%;
    margin: auto;
  }
}

@media only screen and (max-width: 600px) {
  #whatwedo {
    width: 70%;
    height: 200vh;
  }
}

@media only screen and (max-width: 500px) {
  /* html,
  body {
    overflow-x: hidden;
  } */

  main h2 strong {
    font-size: 3rem;
  }
  #spoji {
    transform: translateX(0) !important;
  }
  #bx1,
  #bx2,
  #bx3,
  #bx4 {
    width: 100% !important;
  }
  .nav {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr auto;
  }
  .box:hover {
    height: 45rem;
  }
  nav .homelink {
    z-index: 2;
    justify-self: center;
  }
  nav ul {
    display: none;
  }
  #mobilemenu {
    position: relative;
    display: inline-block;
    grid-column: 2/3;
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #mobilemenuin {
    transition: transform 0.5s ease;
    position: relative;
    display: inline-block;
    height: 0.2rem;
    width: 2.5rem;
    background-color: #fff;
    z-index: 1;
  }

  #mobilemenuin::before {
    transition: transform 0.5s ease;
    content: "";
    position: absolute;
    width: 100%;
    height: 0.2rem;
    top: 1rem;
    background-color: #fff;
    transition: all 1s ease;
  }
  #mobilemenuin::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.2rem;
    top: -1rem;
    background-color: #fff;
    transition: all 1s ease;
  }

  #hmenu:checked + #mobilemenu #mobilemenuin {
    transform: rotate(135deg);
  }

  #hmenu {
    position: absolute;
    right: 2.6rem;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 2;
    grid-column: 2/3;
    top: 2.6rem;
    opacity: 0;
    cursor: pointer;
  }
  #hmenu:checked + #mobilemenu #mobilemenuin::before,
  #hmenu:checked + #mobilemenu #mobilemenuin::after {
    top: 0;
    transform: rotate(90deg);
  }

  #hmenu:checked:hover + #mobilemenu > #mobilemenuin {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
  }

  #hambmobilemenu {
    position: relative;
    display: block;
    transition: height 0.5s ease;
    width: 100%;
    height: 0;
    z-index: 200;
    margin-top: 0 !important;
    grid-column: 1/3;
  }
  #hambmobilemenu ul {
    width: 100%;
    height: fit-content;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
  }
  #hambmobilemenu ul li {
    padding: 1rem;
  }
  #hambmobilemenu ul li a {
    color: #fff !important;
    opacity: 1 !important;
  }

  #hmenu:checked ~ #hambmobilemenu {
    height: 22rem;
  }
  #hmenu {
    visibility: visible;
  }
  .slider {
    margin-top: 70%;
  }
  .slider label {
    width: 70%;
    height: 200%;
  }
  #pocfix {
    opacity: 1 !important;
  }
  label .text {
    position: absolute;
    display: grid;
    grid-template-rows: 1fr 1fr;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 50%;
    text-transform: capitalize;
    letter-spacing: 2px;
  }

  label .text h2 {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    color: #ddd;
    font-size: 2rem;
    text-align: center;
    font-weight: bolder;
  }
  .text .textp {
    grid-row: 2/3;
    margin-top: -5rem;
  }
  span.hide {
    display: none;
  }
  label .text p {
    padding-bottom: 0.7rem;
    margin-left: 2rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bolder;
    text-align: left;
    font-size: 0.9rem;
    text-transform: uppercase;
    position: relative;
    display: block;
    transform: translateY(-2.5rem);
  }
  #slide1 .text p {
    transform: translateY(-4rem);
  }

  label .text p::before {
    content: "";
    display: none;
  }
  label a {
    border-radius: 4px;
    position: absolute;
    font-family: "Roboto Mono";
    bottom: -3%;
    right: 0%;
    text-decoration: none;
    color: #fcbf1e;
    background: rgba(0, 0, 0, 0.7);
    padding: 0.5rem 1rem;
    border: 3px solid #fcbf1e;
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    margin-bottom: 1rem;
    transition: border-color 0.5s ease, color 0.5s ease;
    z-index: 1;
  }
  #spoji {
    /* transform: translateX(-5%); */
  }

  #uppertext {
    text-align: center;
  }
  #uppertext img {
    width: 6rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -40%;
    line-height: 6rem;
  }

  #gridparent {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 1fr);
    height: 200rem;
  }
  .gbox:nth-of-type(1) {
    grid-row: 1/2;
  }
  .gbox:nth-of-type(2) {
    grid-row: 3/4;
  }
  .gbox:nth-of-type(3) {
    grid-row: 5/6;
  }
  .gbox:nth-of-type(4) {
    grid-row: 7/8;
  }

  .gbox:nth-of-type(5) {
    grid-row: 2/3;
  }
  .gbox:nth-of-type(6) {
    grid-row: 4/5;
  }
  .gbox:nth-of-type(7) {
    grid-row: 6/7;
  }
  .gbox:nth-of-type(8) {
    grid-row: 8/9;
  }
  #linijax {
    display: none;
  }

  .gbox:nth-of-type(1),
  .gbox:nth-of-type(2),
  .gbox:nth-of-type(3),
  .gbox:nth-of-type(4),
  .gbox:nth-of-type(5),
  .gbox:nth-of-type(6),
  .gbox:nth-of-type(7),
  .gbox:nth-of-type(8) {
    border-bottom: 1px solid lightgreen;
  }
  #linijay1,
  #linijay2,
  #linijay3 {
    display: none;
  }

  #uslugebody {
    grid-template-columns: 1fr;
    grid-template-rows: 4fr;
  }

  #lookaround {
    grid-template-rows: 1fr 2fr;
    grid-template-columns: 1fr;
  }
  #lookaroundtxt p {
    padding-left: 2rem;
  }
  #lookaroundtxt h3 {
    transform: translateY(-3rem);
  }
  #projects #prev {
    position: absolute;
    bottom: 0% !important;
    left: 5% !important;
    transform: translateY(50%) !important;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;

    border-radius: 4px;
    padding: 2rem 0.5rem;
    transition: background-color 0.4s ease;
  }
  #projects #next {
    padding: 2rem 0.5rem;
  }

  #upperrow {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
  }
  #footersct1 {
    grid-row: 1/2;
    justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5rem;
    transform: translateX(-2.5rem);
  }
  #footersct2 {
    grid-row: 2/3;
    display: flex;
    padding: 0 2rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  #footersct3 {
    grid-row: 3/4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: -2rem;
  }
  #footersct4 {
    grid-row: 4/5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #footer {
    margin: 30rem 0 0;
    display: grid;
    margin-left: -5vw;
    grid-template-rows: repeat(1fr, fit-content);
    width: 100vw;
    background-color: #212529;
    height: fit-content;
    padding-top: 7rem;
    position: relative;
  }
  #hovanim #fillbtn {
    position: absolute;
    bottom: -0.1rem;
    width: 70%;
    margin: 0 auto;
    height: 0.2rem;
    background-color: wheat;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
  }
  #footersct2 strong a {
    text-decoration: none;
    color: wheat !important;
  }
  #footersct2 strong a:hover {
    text-decoration: none;
    color: #333 !important;
  }
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .project {
    width: 77vw !important;
  }
  #uppertext h2 {
    padding: 2rem 1rem;
  }
  #projectsheader .lineh {
    margin-top: 4rem;
  }
  #lookaroundtxt h3 {
    text-align: center;
  }
  #hovanim2 {
  }
  #lookaround {
    position: relative;
    background-color: rgba(0, 0, 0, 0.3);
  }
  #lookaroundtxt {
    position: absolute;
    top: 20%;
    bottom: 0;
  }
  #projects #project {
    position: relative;
    max-width: 77vw;
    height: 35rem;

    /* background-color: rgba(0, 0, 0, 0.5); */
    /* bottom: calc(0px - (35rem / 2)); */
    top: 72vh;
    overflow: hidden;
    /* background-color: red; */
    top: 100% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  label .text {
    display: grid;
    grid-template-rows: 1fr 3fr;
  }
  label .text p {
    font-size: 1vh;
  }
  label .text h2 {
    font-size: 120%;
  }
  #smanjitxt {
    font-size: 100%;
  }
  #slide4 .text h2 span {
    color: #fff;
  }
  #mainimgcontainer {
    grid-template-columns: 1fr !important;
  }
}

@media only screen and (max-width: 1300px) {
  #footer {
    margin: 30rem 0 0;
    display: grid;
    margin-left: -5vw;
    grid-template-rows: repeat(1fr, fit-content);
    width: 100vw;
    background-color: #212529;
    height: fit-content;
    padding-top: 7rem;
    position: relative;
  }
  /* #upperrow {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
  } */

  #footersct3 {
    display: flex;
    margin-left: -5rem;
    align-items: center;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1161px) and (min-width: 501px) {
  #mainimgcontainer {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
  #upperrow {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr fit-content;
  }
  #footersct1 {
    grid-column: 1/-1;
    justify-self: center;
    margin-bottom: 5rem;
    transform: translateX(0) !important;
  }
  #footersct2 {
    justify-self: center;

    padding-left: 3rem;
  }
  #footersct4 {
    justify-self: end;
    padding-right: 3rem;
  }
  .slider {
    transform: translateX(-100%);
    transition: transform 0.6s ease-out;
    user-select: none;
    height: 30vw;
    margin-top: 100px;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
  }

  .slider label {
    margin: auto;
    width: 60vw;
    height: 50vh;
    border-radius: 4px;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
  }
  label .text p {
    padding-bottom: 0.5rem;
    margin-left: 2rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bolder;
    text-align: left;
    font-size: 1.3vw;
    text-transform: uppercase;
    position: relative;
  }
  label .text h2 {
    font-size: 2vw;
  }
  label .text p::before {
    content: "";
    display: none;
  }
  .hide {
    display: none;
  }
  label a {
    border-radius: 4px;
    position: absolute;
    font-family: "Roboto Mono";
    bottom: -3%;
    right: 0%;
    text-decoration: none;
    color: #fcbf1e;
    background: rgba(0, 0, 0, 0.7);
    padding: 0.5rem 1rem;
    border: 3px solid #fcbf1e;
    font-size: 1.4vw;
    text-align: center;
    font-weight: 400;
    margin-bottom: 1rem;
    transition: border-color 0.5s ease, color 0.5s ease;
    z-index: 1;
  }
  .s1overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@media only screen and (max-width: 975px) and (min-width: 501px) {
  #uslugebody {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 2rem;
    padding-top: 5rem;
    border-top: 1px solid rgba(83, 109, 254, 0.1);
    margin-top: 2rem;
  }
  .boxtxt h2 {
    font-size: 2vw;
    align-self: center;
    color: lightgreen;
    padding-bottom: 0rem;
  }

  .boxtxt p {
    text-align: center;
    color: #fff;
    font-size: 1.6vw;
    align-self: center;
  }
  .boxtxt {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 1fr;
    padding: 1rem;
    text-align: center;
  }
}

@media only screen and (max-width: 840px) and (min-width: 501px) {
  nav ul li a {
    text-decoration: none;
    color: #1b6ca8;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding-bottom: 5px;
  }
  #liimg {
    display: none;
  }
}

@media only screen and (max-width: 768px) and (min-width: 501px) {
  .nav {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr fit-content;
  }
  .nav .homelink {
    justify-self: center;
    z-index: 2;
    transform: translateX(-10%);
  }
  .nav ul {
    justify-self: center;
  }

  nav ul li a {
    text-decoration: none;
    color: #1b6ca8;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 1.5rem;
    padding-bottom: 5px;
  }
  nav ul li:nth-of-type(2) {
    margin-left: 0rem;
  }
  .slider label {
    margin: auto;
    width: 60vw;
    height: 50vh;
    border-radius: 4px;
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
  }
  nav ul li {
    padding: 2.9rem 0.6rem;
    position: relative;
    transition: background-color 0.2s ease-out;
    cursor: pointer;
  }
  nav ul li:hover .underline {
    width: 80%;
  }

  #gridparent {
    position: relative;
    margin-top: 15rem;
    background-color: #fff;
    width: 100%;
    height: 400px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    height: 100rem;
  }
  .gbox:nth-of-type(1) {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .gbox:nth-of-type(2) {
    grid-row: 2/3;
  }
  .gbox:nth-of-type(3) {
    grid-row: 3/4;
  }
  .gbox:nth-of-type(4) {
    grid-row: 4/5;
  }

  .gbox:nth-of-type(5) {
    grid-row: 1/2;
    grid-column: 2/3;
  }
  .gbox:nth-of-type(6) {
    grid-row: 2/3;
    grid-column: 2/3;
  }
  .gbox:nth-of-type(7) {
    grid-row: 3/4;
    grid-column: 2/3;
  }
  .gbox:nth-of-type(8) {
    grid-row: 4/5;
    grid-column: 2/3;
  }
  #linijay1,
  #linijay3 {
    display: none;
  }
  .gbox:nth-of-type(1),
  .gbox:nth-of-type(2),
  .gbox:nth-of-type(3),
  .gbox:nth-of-type(5),
  .gbox:nth-of-type(6),
  .gbox:nth-of-type(7),
  .gbox:nth-of-type(8) {
    border-bottom: 1px solid lightgreen;
  }
  .boxtxt {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 1fr;
    padding: 1rem;
    text-align: center;
  }
  .boxtxt h2 {
    align-self: flex-end;
  }
  .project {
    width: 38vw !important;
  }

  #item3 {
    width: 60vw !important;
  }
}

/* USLUGE */
.nav {
  background: rgba(0, 0, 0, 0.5);
}
#headeru {
  height: 100vh;
  width: 100vw;
  /* background-color: red; */
  display: grid;
  grid-template-rows: auto 100%;
  position: relative;
}

#slideru {
  height: 100vh;
  width: 100%;
  background-color: red;
}
#sliderux {
  height: 100%;
  width: 900vw;
  background-color: #192257;
  transform: translateX(-100vw);
  display: flex;
}
.sld {
  width: 100%;
  height: 100%;
}
.sld:first-of-type {
  background: url("/img/usluge/plan.jpeg") no-repeat center center/cover;
}
.sld:nth-of-type(2) {
  background: url("/img/usluge/ormar.jpg") no-repeat center center/cover;
}
.sld:nth-of-type(3) {
  background: url("/img/usluge/slabastrshowc1.jpg") no-repeat left center/cover;
  background-size: cover;
}
.sld:nth-of-type(4) {
  background: url("/img/usluge/Dark-Smart-Home-Background.jpeg") no-repeat left
    center/cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.sld:nth-of-type(5) {
  background: url("/img/usluge/Luxury-apartment-living-room-wallpaper-1920x1200.jpg")
    no-repeat left center/cover;
  background-size: cover;
}
.sld:nth-of-type(6) {
  background: url("/img/usluge/instruments.jpg") no-repeat left center/cover;
  background-size: cover;
}
.sld:nth-of-type(7) {
  background: url("/img/usluge/security-technology.jpg") no-repeat left center /
    cover;
  background-size: cover;
}
.sld:nth-of-type(8) {
  background: url("/img/usluge/plan.jpeg") no-repeat center center/cover;
  background-size: cover;
  /* 50% 0 on tablet mobile */
}
.sld:nth-of-type(9) {
  background: url("/img/usluge/ormar.jpg") no-repeat center center/cover;
  background-size: cover;
}
@media only screen and (max-width: 500px) {
  #bx1 {
    background: url("../../../assets/img/kvservis2.webp") 50% 100% / contain;
  }
  #bx2 {
    background: url("../../../assets/img/electric-eng2.webp") center / cover
      no-repeat;
  }
  #bx3 {
    background: url("../../../assets/img/najntehn3.webp") center / cover
      no-repeat;
  }
  #bx4 {
    background: url("../../../assets/img/davidim2.svg") center center / cover
      no-repeat;
  }
  #projects::after {
    background: url("../../../assets/img/showcaseknx3.webp") no-repeat top
      center / cover;
  }
  .slider label {
    width: 80%;
    height: 250%;
  }
  label .text p {
    font-size: 1.5vh;
  }
  label .text p {
    padding-bottom: 0.7rem;
    margin-left: 2rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bolder;
    text-align: left;
    font-size: 0.9rem;
    text-transform: uppercase;
    position: relative;
    display: block;
    transform: translateY(-2.5rem);
  }
  label .text {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  label .textp {
    position: absolute;
    top: 70%;
  }
  label h2 {
    padding-top: 3rem;
  }
  .s1overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  #showcase {
    background: url("../../../assets/img/LED_Lightings.jpg") no-repeat center
      top/cover;
  }
  #footersct3 {
    margin-left: -2rem !important;
  }
  #footersct1 {
    margin-left: 5rem !important;
  }
  #item3 {
    width: 77vw !important;
  }
}
@media only screen and (max-width: 800px) and (max-height: 500px) {
  #showcaseb,
  #showcase,
  #header {
    height: 200vh;
    width: 100vw;
  }
  #header {
    overflow-y: hidden;
  }
  main {
    position: absolute;
    top: 200vh;
  }
  .slider label {
    margin: auto;
    width: 60vw;
    height: 100vh;
    border-radius: 4px;
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
  }
  #projects #project {
    position: relative;
    max-width: 77vw;
    height: 80vh;
    padding: 0 auto;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* bottom: calc(0px - (35rem / 2)); */
    top: 55%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    /* background-color: red; */
  }
  #item3 {
    width: 60vw !important;
  }
  /* html,
  body {
    overflow-x: hidden;
  } */
}
@media only screen and (max-width: 300px) {
  main h2 strong {
    font-size: 2rem;
  }
  #footersct1 h2 {
    font-size: 2rem !important;
  }
  #footersct4 ul {
    padding-left: 2rem;
  }
}
@media only screen and (max-width: 600px) and (min-width: 501px) {
  #footersct3 ul li a {
    font-size: 1.3rem !important;
  }
  #upperrow {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  #footersct4 {
    justify-self: center !important;
    align-self: center !important;
    margin: auto;
    padding-right: 0 !important;
    grid-column: 1/-1;
    margin-top: 3rem;
  }
  #footersct4 h2 {
    text-align: center;
  }
}
/* @media only screen and (max-height: 1080px) {
  #projects #project {
    top: 83%;
  }
}
@media only screen and (max-height: 1200px) {
  #projects #project {
    top: 83%;
  }
}
@media only screen and (max-height: 1440px) {
  #projects #project {
    top: 87%;
  }
}
@media only screen and (max-height: 1536px) {
  #projects #project {
    top: 89%;
  }
} */
@media only screen and (max-width: 900px) and (max-height: 500px) {
  main {
    top: 100vh !important;
  }
  #whatwedo {
    height: 220vh;
    /* height: 100vh; */
  }
}
@media only screen and (min-width: 1500px) {
  #one {
    width: 25.2vw !important;
  }
}
@media only screen and (min-width: 2130px) {
  #one {
    width: 25.3vw !important;
  }
}
