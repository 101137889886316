@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

p {
  font-size: 1.5rem;
  color: #6b7c93;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  text-align: left;
  line-height: 3rem;
}
.fa-users,
.fa-building,
.fa-house-damage {
  color: #fff;
  border: 0.1rem solid #fff;
  padding: 5rem;
  border-radius: 50%;
}
.iel {
  width: 0.1rem;
  height: 5rem;
  background-color: #fff;
  position: relative;
  margin-bottom: 3rem;
}
.iel::before {
  width: 2rem;
  height: 2rem;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border: 0.1rem solid #fff;
  border-radius: 50%;
  transform: translateX(-50%) translateY(100%);
}
#workdone {
  height: 80vh;
  /* background: url(../../assets/img/onamabackg.jpg) no-repeat center center/cover; */
  /* background-attachment: fixed; */
  background-color: #6b7c93;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-around;
  align-items: center;
}
h3 {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  letter-spacing: 0.1rem;
  color: #fff;
}
#workdone h3 {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  grid-column: 1/-1;
  grid-row: 1/2;
}
.workdoneb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-row: 2/3;
}
#workdone > h3 {
  margin-top: 5rem;
  margin-bottom: 0rem;
  font-size: 2.9rem;
  line-height: 6rem;
  position: relative;
}
#workdone > h3::before {
  position: absolute;

  content: "";
  width: 8rem;
  height: 0.2rem;
  background-color: #f4bc16;
  bottom: -10%;
  left: 50%;
  transform: translateX(-50%);
}
.workcol {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.workcol h3 {
  font-size: 5rem;
  font-weight: 400;
}
.workcol p {
  color: #fff;
  text-transform: uppercase;
  font-family: "Raleway", Arial, Helvetica, sans-serif;
}
#onama {
  width: 80%;
  margin: auto;
  padding-bottom: 5rem;
}
#onama p {
  color: #444;
  font-family: "Raleway";
  font-size: 1.5rem;
  line-height: 24px;
  margin-top: 24px;
}
#onama h3 {
  color: #444;
  font-family: "Raleway";
  font-size: 2rem;
  margin-top: 2rem;
}
#knxpart {
  display: flex;
  flex-direction: column;
}
#ourteam {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0vw;
  position: relative;
}
#ourteam h2 {
  font-size: 3rem;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  letter-spacing: 0.1rem;
  color: #444;
}
#ourteam h2::before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #6b7c93;
  bottom: 0;
}
#ourteam h2::after {
  display: block;
  content: "";
  position: absolute;
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background-color: #6b7c93;
  bottom: -20%;
}
#slidecontainbtns {
  width: 90vw;
  height: 40vh;
  position: relative;
}
#slidecontain {
  margin: auto;
  width: 100%;
  height: 40vh;
  position: relative;
  overflow: hidden;
}
.item {
  margin-right: 1rem;
  width: 30vw;
  /* width: 28.5vw !important; */
  height: 100%;
  position: relative;
  overflow: hidden;
}
.item2 {
  margin-right: 1rem;
  width: 45vw;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.item3 {
  margin-right: 1rem;
  width: 50vw;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.item::after,
.item2::after,
.item3::after {
  content: "";
  position: absolute;
  display: block;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.9;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 120%;
  -webkit-transform: scale(2.2) translateX(-75%) translateY(-75%) rotate(-28deg);
  transform: scale(2.2) translateX(-75%) translateY(-75%) rotate(-28deg);
  -webkit-transition: -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
  transition: -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
  transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
  transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1),
    -webkit-transform 3s cubic-bezier(0.2, 1, 0.3, 1);
}
.item:hover::after,
.item2:hover::after,
.item3:hover::after {
  -webkit-transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
  transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
}

.itemtxt p {
  color: #fff;
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  text-align: center;
}
.itemtxt h4 {
  letter-spacing: 0.15rem;
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-size: 2.5rem;
  padding: 0 1rem;
  margin: 0.5rem 0 0 0;
}
.item:hover .itemtxt,
.item2:hover .itemtxt,
.item3:hover .itemtxt {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.itemtxt {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 0;
  opacity: 0;
  text-align: center;
  z-index: 1;
  color: #fff;
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
  -webkit-transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
    -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
  transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
    -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
  transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
    transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
  transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
    transform 500ms cubic-bezier(0.2, 1, 0.3, 1),
    -webkit-transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
  -webkit-transition-delay: 300ms;
  transition-delay: 300ms;
}
.itemtxtwrap {
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.item:hover .itemimg,
.item2:hover .itemimg,
.item3:hover .itemimg {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
#slidetranslate {
  position: absolute;
  left: 0;
  min-width: 300vw;
  height: 100%;
  display: flex;
}
#nexto {
  cursor: pointer;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: -4%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 2rem 1rem;
  transition: background-color 0.4s ease;
}
#prevo {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  left: -4%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;

  border-radius: 4px;
  padding: 2rem 1rem;
  transition: background-color 0.4s ease;
}

#oneo img,
#twoo img,
#threeo img {
  width: 100%;
  height: 100%;
}
#oneo .itemimg,
#twoo .itemimg,
#threeo .itemimg {
  width: 100%;
  height: 100%;
}
#oneo {
  background: url(../../assets/img/radnik1.jpg) no-repeat center center/cover;
}
#twoo {
  background: url(../../assets/img/radnik2.jpg) no-repeat center center/cover;
}
#threeo {
  background: url(../../assets/img/radnik3.jpg) no-repeat center center/cover;
}
#fouro {
  background: url(../../assets/img/radnik4.jpg) no-repeat center center/cover;
}
#fiveo {
  background: url(../../assets/img/radnik5.jpg) no-repeat center center/cover;
}
#sixo {
  background: url(../../assets/img/radnik6.jpg) no-repeat center center/cover;
}
#seveno {
  background: url(../../assets/img/radnik7.jpg) no-repeat center center/cover;
}
#eighto {
  background: url(../../assets/img/radnik8.jpg) no-repeat center center/cover;
}
#nineo {
  background: url(../../assets/img/radnik9.jpg) no-repeat center center/cover;
}
#teno {
  background: url(../../assets/img/radnik10.jpg) no-repeat center center/cover;
}
#prevo:hover,
#nexto:hover {
  background-color: #6b7c93;
}
@media only screen and (max-width: 500px) {
  .item {
    background-size: contain !important;
    background-size: 100% 100% !important;
  }
  #oneo,
  #twoo,
  #threeo,
  #fouro,
  #fiveo,
  #sixo,
  #seveno,
  #eighto,
  #nineo,
  #teno {
    width: 90vw !important;
  }
  #slidetranslate {
    width: fit-content;
  }
  .slidecontain {
    width: fit-content !important;
  }

  #nav {
    background-color: rgba(0, 0, 0, 0.5);
  }
  #hambmobilemenu {
    position: relative;
    display: block;
    transition: height 0.5s ease;
    width: 100%;
    height: 0;
    z-index: 200;
    margin-top: 0 !important;
    grid-column: 1/3;
  }
  #hambmobilemenu ul {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    transform: translateY(10rem);
    opacity: 0;
    transition: transform 0.5s ease 0s, opacity 0.2s ease 0s, display 1s ease 0s;
    display: none;
  }
  #hambmobilemenu ul li {
    padding: 1rem;
  }
  #hambmobilemenu ul li a {
    color: #fff !important;
    opacity: 1 !important;
  }

  #hmenu:checked ~ #hambmobilemenu {
    height: 22rem;
  }
  #nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
  }
  #onamahead {
    position: relative;
    height: 100vh !important;
  }
  #onamahead h1 {
    width: 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    text-align: center;
    font-size: 4.5rem !important;
    transform: translate(-50%, -50%);
  }
  #onamahead h2 {
    width: 100%;
    position: absolute;
    text-align: center;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #workdone {
    height: fit-content;
    /* background: url(../../assets/img/onamabackg.jpg) no-repeat center */
    /* center/cover; */
    /* background-attachment: fixed; */
    background-color: #6b7c93;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 5rem;
  }
  #workdone h3 {
    grid-column: 1/-1;
    width: 100%;
  }
  .workdoneb {
    margin-bottom: 5rem;
  }
  #slidecontain {
    height: 70vh;
    width: 80vw !important;
  }
  #nexto {
    bottom: 10%;
    right: -4%;
  }
  #prevo {
    position: absolute;
    bottom: 10%;
    transform: translateY(50%);
    left: -4%;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    border-radius: 4px;
    padding: 2rem 1rem;
    transition: background-color 0.4s ease;
  }
  #slidecontain {
    border-radius: 0.5rem;
  }
  #ourteam {
    padding-bottom: 5rem;
  }
  #fouro {
    background: url(../../assets/img/radnik4.jpg) no-repeat right center/cover;
  }
  #nineo {
    background: url(../../assets/img/radnik9.jpg) no-repeat right center/cover;
  }
  .itemtxt {
    width: 80vw;
  }
  .itemtxt h4,
  .itemtxt p {
    width: 100%;
    text-align: center;
  }
}
@media only screen and (min-width: 1400px) {
  #slidecontainbtns {
    width: 90vw;
    height: 60vh;
    position: relative;
  }
  #slidecontain {
    margin: auto;
    width: 100%;
    height: 60vh;
    position: relative;
    overflow: hidden;
  }
  #oneo {
    background: url(../../assets/img/radnik1.jpg) no-repeat top center/cover;
  }
  #twoo {
    background: url(../../assets/img/radnik2.jpg) no-repeat top center/cover;
  }
  #threeo {
    background: url(../../assets/img/radnik3.jpg) no-repeat top center/cover;
  }
}
@media only screen and (max-height: 500px) {
  #workdone > h3 {
    margin-top: 5rem;
    margin-bottom: 5rem;
    font-size: 2.9rem;
    line-height: 6rem;
    position: relative;
  }
}
#onamahead {
  margin-top: 0rem;
  width: 100%;
  height: 70vh;
  height: 47rem;
  background: url(../../assets/img/admin/bulb1.jpg) no-repeat center
    center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
  font-family: "Indie Flower", cursive;
  position: relative;
}
#onamahead::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
#onamahead h1 {
  font-size: 7rem;
  color: #f4bc16;
  z-index: 99;
}
#onamahead h2 {
  font-size: 4rem;
  font-weight: 400;
  z-index: 99;
}
#farbaj {
  color: #1b6ca8;
}
#ourteam {
  background-color: lightcyan;
}
@media only screen and (min-height: 501px) and (max-width: 500px) {
  .workdoneb:first-of-type {
    margin-top: 5rem;
  }
}
@media only screen and (min-width: 501px) and (max-width: 768px) {
  #onamahead {
    margin-top: 0rem;
    width: 100%;
    height: 470px;
    background: url(../../assets/img/admin/bulb1.jpg) no-repeat center
      center/cover;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    flex-direction: column;
    font-family: "Indie Flower", cursive;
  }
  #onamahead h2 {
    padding-bottom: 7rem;
  }
  #slidecontain {
    overflow: hidden !important;
    width: 98% !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 916px) {
  #slidecontain {
    overflow: hidden !important;
    width: 98% !important;
  }
}
@media only screen and (min-width: 918px) and (max-width: 1093px) {
  #slidecontain {
    overflow: hidden !important;
    width: 98% !important;
  }
}
@media only screen and (max-width: 380px) {
  #onamahead h1 {
    font-size: 3.5rem !important;
  }
}
@media only screen and (max-width: 1500px) {
  .item {
    width: 29.5vw !important;
  }
}
@media only screen and (max-width: 1093px) {
  .item {
    width: 28.8vw !important;
  }
}
@media only screen and (max-width: 600px) {
  .item {
    width: 28vw !important;
  }
}
@media only screen and (max-width: 900px) and (max-height: 500px) {
  #workdone {
    height: 150vh;
    height: fit-content;
  }
}
