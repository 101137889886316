#header {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  /* z-index: 1; */
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}
.s1overlay,
.s5overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.textp p {
  display: flex;
}
.textp p span:nth-of-type(1) {
  margin-right: 1rem;
}
#showcase {
  z-index: -1;
  position: absolute;
  top: -100%;
  left: 0;
  background: url('../../../assets/img/LED_Lighting.jpg') no-repeat center
    top/cover;
  width: 100%;
  height: 100%;
  transition: top 0.7s ease-in-out;
  overflow-x: hidden;
}

#showcaseb {
  position: absolute;
  transition: top 0.5s ease-out;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: -100%;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}

.slider {
  transform: translateX(-100%);
  transition: transform 0.6s ease-out;
  user-select: none;
  height: 30vw;
  margin-top: 180px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  z-index: 1;
}
label img {
  border-radius: 4px;
}
.slider label {
  margin: auto;
  width: 50%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
  left: 0;
  right: 0;
  cursor: pointer;
  transition: transform 0.4s ease;
}

#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3 {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(-30%, 0, -200px);
}
#s1:checked ~ #slide5,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(-15%, 0, -100px);
}
#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5 {
  box-shadow: 0 13px 25px 0 rgba(0, 0, 0, 0.3), 0 11px 7px 0 rgba(0, 0, 0, 0.19);
  transform: translate3d(0, 0, 0);
}
#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide1 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  transform: translate3d(15%, 0, -100px);
}
#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2 {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  transform: translate3d(30%, 0, -200px);
}

label .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-transform: capitalize;
  letter-spacing: 2px;
}

label .text h2 {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  color: #ddd;
  font-size: 1.5vw;
  text-align: center;
  font-weight: bolder;
  margin-bottom: 1rem;
}
label .text h2 span {
  font-weight: 600;
  color: #fcbf1e;
}
label .textp {
  position: absolute;
  top: 140%;
}
#kamere {
  text-align: left;
  padding-left: 3rem;
}

label .text p {
  padding-bottom: 0.5rem;
  margin-left: 2rem;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: bolder;
  text-align: left;
  font-size: 0.9vw;
  text-transform: uppercase;
  position: relative;
}

label .text p::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background: #fcbf1e;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-100%);
}
.hide {
  visibility: hidden;
}
#fix {
  color: #fff;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 0.9vw;
}

label a {
  border-radius: 4px;
  position: absolute;
  font-family: 'Roboto Mono';
  bottom: 5%;
  right: 10%;
  text-decoration: none;
  color: #fcbf1e;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem 1.5rem;
  border: 3px solid #fcbf1e;
  font-size: 1.4vw;
  text-align: center;
  font-weight: 400;
  margin-bottom: 1rem;
  transition: border-color 0.5s ease, color 0.5s ease;
  z-index: 1;
}
label a:hover {
  border: 3px solid #303960;
  color: #1b6ca8;
}
label a:hover .swipehover {
  width: 11.8vw;
}
.swipehover {
  border-radius: 4px;
  transition: width 0.5s ease;
  transform: rotateX(180deg);
  position: absolute;
  bottom: 5%;
  right: 10.1%;
  width: 11.8vw;
  width: 0vw;
  height: 50px;
  font-size: 1.4vw;
  background: red;
  z-index: 0;
  margin-bottom: 1rem;
}
[type='radio'] {
  display: none;
}

@media only screen and (max-width: 1161px) and (min-width: 501px) {
  .slider {
    margin-top: 100px;
  }
}
@media only screen and (max-width: 768px) and (min-width: 501px) {
  .slider label {
    margin: auto;
    width: 60vw;
    height: 50vh;
    border-radius: 4px;
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
  }
}
@media only screen and (max-width: 500px) {
}
