#adminBg {
  background: url(../../assets/img/admin/bulb1.jpg) no-repeat center
    center/cover;
  width: 100vw;
  height: 100vh;
}

#formAdmin {
  margin-top: 0 !important;
  height: 70%;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(51, 51, 51, 0.5);
}

#formAdmin input {
  height: 10%;
}

#formAdmin label {
  font-family: 'Raleway';
  letter-spacing: 0.2rem;
  color: #e0ffff;
  margin-bottom: 1rem;
}

#formTitle {
  text-align: center;
  color: #e0ffff;
  /* text-transform: uppercase; */
  font-size: 3rem;
  margin-bottom: 5rem;
  font-family: 'Raleway';
  font-weight: lighter;
}

#signInBtn {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 30%,
    rgba(6, 87, 173, 1) 69%,
    rgba(0, 212, 255, 1) 100%
  );
  /* background: linear-gradient(90deg, #1CB5E0 0%, #000851 100%); */
  /* background: linear-gradient(90deg, #00d2ff 0%, #3a47d5 100%); */
  /* background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%); */
  width: 100%;
  border: none;
  text-transform: uppercase;
}

#spinnerParent {
  background-color: rgba(51, 51, 51, 0.5);
  background-color: rgba(6, 6, 14, 0.9);
  width: 100%;
  height: 50%;
  border-radius: 0.5rem;
  /* height: 100vh; */
  padding: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  grid-template-rows: 1fr auto;
}

#spinnerParent #notification {
  text-align: center;
}

#spinnerParent span {
  color: #e0ffff;
  margin-right: 0.1rem;
  font-weight: lighter;
  text-align: center;
  display: inline-block;
  font-size: 3rem;
  letter-spacing: 0.2rem;
  animation: animate 1.5s ease-in-out infinite;
  animation-delay: calc(0.1s * var(--i));
  -webkit-box-reflect: below -12px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}

.loader {
  color: lightseagreen;
  color: #02b1e8;
  font-size: 20px;
  margin: 0 auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  right: 50%;
  top: 50%;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0) translateX(-50%) translateY(-50%);
}

#check {
  color: green;
  font-size: 3rem;
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes animate {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-20px);
  }
  40%,
  100% {
    transform: translateY(0px);
  }
}

#error {
  font-size: 80px;
  justify-self: center;
  align-self: center;
  color: #e74c3c;
}

#errorTxt {
  margin-bottom: 2rem;
  justify-self: center;
  align-self: center;
  color: #e74c3c;
}

#errorParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 500px) {
  #formAdmin {
    width: 90vw;
  }
}
@media only screen and (max-width: 900px) and (max-height: 500px) {
  #adminBg {
    height: 250vh;
  }
  #formAdmin {
    width: 90vw;
    height: fit-content;
    transform: translateX(-50%);
    top: 0;
  }
  #formAdmin h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  #formAdmin label {
    font-size: 1rem;
  }
  #formAdmin input[type='submit'] {
    font-size: 1rem;
    line-height: 1px;
    padding: 1.5rem;
  }
  #spinnerParent {
    height: 70%;
  }
}
