@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

nav ul li a {
  text-decoration: none;
  color: #144972;
  font-weight: bolder;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 1.5rem;
  font-weight: 400;
  padding-bottom: 5px;
  color: #fff;
}
#nav {
  /* background: transparent; */
}
#portf,
#rasvjeta,
#solar,
#mrezeitel,
#kamerer {
  height: fit-content;
  width: 100%;
}
#portfb {
  width: 80%;
  margin: 5rem auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 34.5rem;
  gap: 0.5rem;
}

#rasvjetab {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-auto-rows: 255px;
  grid-auto-flow: dense;
  width: 100%;
  margin: 5rem auto;
}

#projs2Cnt {
  width: 80vw;
  margin: auto;
  display: flex;
  flex-direction: column;
}

#projs2Cnt h3 {
  text-align: center;
  font-size: 3rem;
  color: #144972;
  margin-bottom: 5rem;
}

#rasvjetab2 {
  /* display: grid; */
  gap: 0.5rem;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  /* grid-template-columns: repeat(2, 1fr); */
  /* grid-auto-rows: 255px;
  grid-auto-flow: dense; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: 80vw;
}
#rasvjetab2 .portfcol img {
  width: 100%;
  height: 100%;
}

#solarb,
#mrezeitelb {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 255px;
  grid-auto-flow: dense;
  width: 100%;
  margin: 5rem auto;
}
#pamkucb,
#kamererb {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 255px;
  grid-auto-flow: dense;
}
.horizontal {
  grid-column: auto / span 2;
}
.vertical {
  grid-row: span 2;
  grid-column: auto / span 2;
}
.big {
  grid-column: span 2;
  grid-row: span 2;
}
.whyy {
  grid-column: span 1;
}
#rasvjeta,
#solar,
#mrezeitel,
#pamkuc,
#kamerer {
  width: 80%;
  margin: 10rem auto 0;
  height: fit-content !important;
}
#portfb img,
#rasvjetab img,
#solarb img,
#mrezeitelb img,
#pamkucb img,
#kamererb img {
  width: 100%;
}
#rasvjetab img,
#solarb img,
#mrezeitelb img,
#pamkucb img,
#kamererb img {
  height: 100%;
}
#rasvjeta h3,
#solar h3,
#mrezeitel h3,
#pamkuc h3,
#kamerer h3 {
  text-align: center;
  margin-bottom: 5rem;
  font-size: 5rem;
  font-family: "Roboto", "sans-serif";
  font-weight: lighter;
  color: #144972;
}
#portftxt {
  font-family: "Montserrat";
  width: 80%;
  text-align: center;
  margin: 50px auto 70px;
}
#portftxt h1 {
  color: #144972;
  font-size: 5rem;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 2rem;
}
#portftxt p {
  font-size: 2rem;
  text-align: center;
}
.portfcol {
  position: relative;
  width: 100%;
  height: 100%;
  transition: background-color;
  overflow: hidden;
  cursor: pointer;
}
#rasvjetab2 .portfcol {
  flex-grow: 1;
  flex-basis: 30rem;
  max-width: 36rem;
  height: 25.5rem;
}
.portfcol img,
.portfcoltxt h3 {
  user-select: none;
}

#portfb .portfcol img {
  width: 100%;
  height: 100% !important;
}
#portfb .portfcol::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  transition: background-color 0.5s ease;
}
.portfcol:hover::before {
  background: rgba(0, 0, 0, 0.7);
  /* color: rgb(44, 109, 248); */
}
.portfcoltxt {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
  font-family: "Raleway", sans-serif;
}
.portfcoltxt h3 {
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 5rem;
  font-weight: 500;
  letter-spacing: 0.15em;
  text-align: center;
}
.portfcoltxt p {
  font-size: 1.5rem;
}
.portfcol:nth-of-type(even) .portfcoltxt {
  transform: translateX(50%);
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.portfcol:nth-of-type(odd) .portfcoltxt {
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.portfcol:nth-of-type(even):hover .portfcoltxt {
  transform: translateX(0);
  opacity: 1;
}
.portfcol:nth-of-type(odd):hover .portfcoltxt {
  transform: translateX(0);
  opacity: 1;
}
.radovi {
  height: 470px;
  width: 100%;
  background: url(../../assets/img/usluge/Dark-Smart-Home-Background.jpeg)
    no-repeat center center/cover;
  position: relative;
}
.radovi::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;

  background: linear-gradient(
    to right,
    rgba(55, 83, 207, 0.98) 0,
    rgba(19, 11, 31, 0.065) 100%
  );
}
#nav {
  background-color: transparent;
}
#port1,
#port2,
#port3,
#port4,
#port5,
#port6,
#port7 {
  width: 80%;
  margin: auto;
  text-align: center;
}
#port1 h1,
#port2 h1,
#port3 h1,
#port4 h1,
#port5 h1,
#port6 h1,
#port7b h1 {
  font-size: 3rem;
  font-family: "Raleway", sans-serif;
  line-height: 5rem;
  margin: 5rem auto;
  color: #333;
  text-transform: uppercase;
}
#port1b,
#port2b,
#port3b,
#port4b,
#port5b,
#port6b,
#port7b {
  margin: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-auto-rows: 30rem;
  grid-auto-flow: dense;
  gap: 5rem;
}
#port1b img,
#port2b img,
#port3b img,
#port4b img,
#port5b img,
#port6b img,
#port7b img {
  height: 100%;
  width: 100%;
}
.port1bimg {
  position: relative;
  cursor: pointer;
}
.port1bimg:hover::before {
  opacity: 1;
}
.port1bimg::before {
  position: absolute;
  pointer-events: none;
  content: "+";
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 5rem;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
}
body {
  position: relative;
}
.overlayp {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease, z-index 0.1s ease;
  z-index: -1;
}
.xmarkcon {
  position: fixed;
  right: 5rem;
  top: 5rem;
  color: #fff;
  width: 5rem;
  height: 5rem;
  z-index: 222;
  cursor: pointer;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.xmarkcon i {
  transition: color 0.5s ease;
  width: 100%;
  height: 100%;
  color: #fff;
}
.xmarkcon:hover i,
.fachevronright:hover,
.fachevronleft:hover {
  color: aqua;
}
.fachevronright {
  opacity: 0;
  z-index: -1;
  transition: 0.3s color ease;
  position: fixed;
  top: 50%;
  right: 5rem;
  color: #fff;
  cursor: pointer;
}
.fachevronleft {
  z-index: -1;
  opacity: 0;
  transition: 0.3s color ease;
  position: fixed;
  top: 50%;
  left: 5rem;
  color: #fff;
  cursor: pointer;
}
.slikaCon {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  width: 80%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}
.slikaCon img {
  max-width: 80%;
}
#rasvjetab .portfcol:hover::before,
#rasvjetab2 .portfcol:hover::before,
#solarb .portfcol:hover::before,
#mrezeitelb .portfcol:hover::before,
#pamkucb .portfcol:hover::before,
#kamererb .portfcol:hover::before {
  opacity: 1;
}
#rasvjetab .portfcol,
#solarb .portfcol,
#mrezeitelb .portfcol,
#pamkucb .portfcol,
#kamererb .portfcol {
  cursor: pointer;
}
#rasvjetab .portfcol::before,
#rasvjetab2 .portfcol::before,
#solarb .portfcol::before,
#mrezeitelb .portfcol::before,
#pamkucb .portfcol::before,
#kamererb .portfcol::before {
  pointer-events: none; /*amazing for overlays so i can click on the image for js instead of the overlay*/
  transition: opacity 0.3s ease;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  color: #fff;
  content: "+";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 500px) {
  #nav {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .mh {
    grid-column: span 1;
  }
  .horizontal {
    grid-column: auto / span 1;
  }

  .big {
    grid-column: span 1;
    grid-row: span 1;
  }

  div.portfcol {
    width: 100% !important;
    display: block;
  }
  .portfcol img {
    display: block !important;
  }
  #portfb {
    width: 80%;
    margin: 5rem auto;
    display: grid;
    grid-template-columns: 1fr;
    /* grid-auto-rows: 34.5rem; */
    gap: 0.5rem;
  }
  .xmarkcon {
    position: fixed;
    right: 0.3rem;
    top: 10rem;
    color: #fff;
    width: 5rem;
    height: 5rem;
    z-index: 222;
    cursor: pointer;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .xmarkcon i {
    transition: color 0.5s ease;
    width: 100%;
    height: 100%;
    color: #fff;
  }
  .fa-chevron-right {
    opacity: 0;
    z-index: -1;
    transition: 0.3s color ease;
    position: fixed;
    top: 50%;
    right: 0.5rem;
    color: #fff;
    cursor: pointer;
  }
  .fa-chevron-left {
    z-index: -1;
    opacity: 0;
    transition: 0.3s color ease;
    position: fixed;
    top: 50%;
    left: 0.5rem;
    color: #fff;
    cursor: pointer;
  }
  #portftxt h1 {
    margin-top: 5rem;
  }
  .slikaCon img {
    max-width: 85%;
  }
  .slikaCon {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
  }
  #totop1 {
    position: fixed;
    left: 50%;
    bottom: -3.5%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    background-color: #536dfe;
    z-index: 10;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  #totop1 i {
    color: #fff;
  }
  #totop1:hover {
    transform: translateY(-1rem);
    transform: translate(-50%, -60%);
  }

  #totop1:hover i {
    animation: totop1 0.7s ease forwards infinite;
  }

  @keyframes totop1 {
    from {
      opacity: 1;
      transform: translateY(0px);
    }
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  #rasvjeta {
    width: 80vw !important;
    margin: auto;
  }
  #rasvjetab .portfcol {
    width: 100% !important;
  }
  #rasvjetab .portfcol img {
    width: 100%;
  }

  #solarb .portfcol,
  #mrezeitelb .portfcol,
  #pamkucb .portfcol,
  #kamererb .portfcol {
    width: 80vw !important;
  }
  #rasvjetab {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: 255px;
    grid-auto-flow: dense;
    width: 100% !important;
  }
  #rasvjetab2 {
    width: 80vw;
    grid-template-columns: 1fr;
  }
  .radovi {
    height: 470px;
    width: 100%;
    background: url("../../assets/img/usluge/Dark-Smart-Home-Background.jpeg")
      no-repeat 20% center/cover;
    position: relative;
  }
  .portfcoltxt {
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-direction: column;
    font-family: "Raleway", sans-serif;
  }
  .portfcoltxt h3 {
    text-transform: uppercase;
    font-size: 2.5rem;
    line-height: 5rem;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-align: center;
  }
  .portfcoltxt p {
    font-size: 1.5rem;
  }
  .portfcol:nth-of-type(even) .portfcoltxt {
    transform: translateX(20%);
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  .portfcol:nth-of-type(odd) .portfcoltxt {
    transform: translateX(-20%);
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  #port1b,
  #port2b,
  #port3b,
  #port4b,
  #port5b,
  #port6b,
  #port7b {
    width: 80vw !important;
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 600px) {
  .slikaCon img {
    max-width: 85%;
    min-height: 60vh;
  }
  .slikaCon {
    width: 100%;
  }
}
@media only screen and (max-width: 1000px) {
  #portfb {
    grid-auto-rows: 25rem;
  }
}
@media only screen and (max-width: 1079px) and (min-width: 620px) {
  #port1b,
  #port2b,
  #port3b,
  #port4b,
  #port5b,
  #port6b,
  #port7b {
    margin: auto;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
    grid-auto-rows: 30rem;
    grid-auto-flow: dense;
    gap: 5rem;
  }
  .portfcoltxt h3 {
    text-transform: uppercase;
    font-size: 2.5rem;
    line-height: 5rem;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-align: center;
  }
  .portfcoltxt p {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 800px) and (min-width: 501px) {
  .portfcoltxt h3 {
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 5rem;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-align: center;
  }
  .portfcoltxt p {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 620px) and (min-width: 501px) {
  .portfcoltxt h3 {
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 5rem;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-align: center;
  }
  .portfcoltxt p {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 750px) and (min-width: 501px) {
  #rasvjetab {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
/* @media only screen and (max-width: 500px) {
  #rasvjetab {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
} */

@media only screen and (max-width: 700px) and (min-width: 501px) {
  #portfb {
    width: 80%;
    margin: 5rem auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 25rem;
    gap: 0.5rem;
  }
}
