@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&family=Roboto+Mono:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  overflow-x: hidden;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}
#hmenu {
  visibility: hidden;
}

body {
  overflow-x: hidden;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}
body::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}
nav ul {
  z-index: 2000 !important;
}
.nav {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.18); */
  /* position: sticky;
  top: 0; */
}
.logo {
  width: 30%;
  display: flex;
  align-items: center;
  z-index: 1;
}
nav a {
  text-decoration: none;
}
.logoimg {
  width: 80px;
  z-index: 2;
}

.ones {
  font-size: 2.8rem;
  color: #ffcd3c;
  z-index: 2;
  letter-spacing: 2px;
}
.twos {
  margin-left: 0.5rem;
  color: #1b6ca8;
  z-index: 2;
  font-size: 2rem;
  padding-bottom: 0.2rem;
  font-weight: bolder;
}
.hmenu {
  visibility: hidden;
}

#hambmobilemenu {
  display: none;
}
.mobilemenuin,
.mobilemenuin::before,
.mobilemenuin::after {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Not implemented yet */
  -o-user-select: none;
  user-select: none;
}
.mobilemenu {
  user-select: none;
}

.hambmobilemenu {
  display: none;
}

#pocfix {
  opacity: 1 !important;
}

.current {
  color: #ffcd3c;
}

nav ul {
  display: flex;
  list-style-type: none;
  align-self: center;
  z-index: 0;
  position: relative;
  margin-right: 3rem;
  z-index: 2;
}
nav ul li:hover {
  background-color: #303960;
}
nav ul li:hover .underline {
  width: 80%;
}

.underline {
  display: inline-block;
  background-color: #ffcd3c;
  width: 0%;
  height: 2px;
  z-index: 10;
  position: absolute;
  top: 70%;
  left: 10%;
  transition: width 0.5s ease;
}
nav ul li {
  padding: 2.9rem 1rem;
  position: relative;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
}

nav ul li a {
  text-decoration: none;
  color: #1b6ca8;
  font-weight: bolder;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 1.5rem;
  padding-bottom: 5px;
}

@media only screen and (max-width: 1300px) {
  #footer {
    margin: 30rem 0 0;
    display: grid;
    margin-left: -5vw;
    grid-template-rows: repeat(1fr, fit-content);
    width: 100vw;
    background-color: #212529;
    height: fit-content;
    padding-top: 7rem;
    position: relative;
  }
  /* #upperrow {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
  } */

  #footersct3 {
    display: flex;
    margin-left: -5rem;
    align-items: center;
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  html,
  body {
    overflow-x: hidden;
  }
  .nav {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr auto;
  }
  .box:hover {
    height: 45rem;
  }
  nav .homelink {
    justify-self: center;
  }
  nav ul {
    display: none;
  }
  #mobilemenu {
    position: relative;
    display: inline-block;
    grid-column: 2/3;
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #mobilemenuin {
    transition: transform 0.5s ease;
    position: relative;
    display: inline-block;
    height: 0.2rem;
    width: 2.5rem;
    background-color: #fff;
    z-index: 1;
  }

  #mobilemenuin::before {
    transition: transform 0.5s ease;
    content: '';
    position: absolute;
    width: 100%;
    height: 0.2rem;
    top: 1rem;
    background-color: #fff;
    transition: all 1s ease;
  }
  #mobilemenuin::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.2rem;
    top: -1rem;
    background-color: #fff;
    transition: all 1s ease;
  }

  #hmenu:checked + #mobilemenu #mobilemenuin {
    transform: rotate(135deg);
  }

  #hmenu {
    position: absolute;
    right: 2.6rem;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 2;
    grid-column: 2/3;
    top: 2.6rem;
    opacity: 0;
    cursor: pointer;
  }
  #hmenu:checked + #mobilemenu #mobilemenuin::before,
  #hmenu:checked + #mobilemenu #mobilemenuin::after {
    top: 0;
    transform: rotate(90deg);
  }

  #hmenu:checked:hover + #mobilemenu > #mobilemenuin {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
  }

  #hambmobilemenu {
    position: relative;
    display: block;
    transition: height 0.5s ease;
    width: 100%;
    height: 0;
    z-index: 200;
    margin-top: 0 !important;
    grid-column: 1/3;
  }
  #hambmobilemenu ul {
    width: 100%;
    height: fit-content;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
  }
  #hambmobilemenu ul li {
    padding: 1rem;
  }
  #hambmobilemenu ul li a {
    color: #fff !important;
    opacity: 1 !important;
  }

  #hmenu:checked ~ #hambmobilemenu {
    height: 22rem;
  }
  #hmenu {
    visibility: visible;
  }
  #slider {
    margin-top: 70%;
  }
  #slider label {
    width: 70%;
    height: 200%;
  }
  #pocfix {
    opacity: 1 !important;
  }
  label .text {
    position: absolute;
    display: grid;
    grid-template-rows: 1fr 1fr;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 50%;
    text-transform: capitalize;
    letter-spacing: 2px;
  }

  label .text h2 {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    color: #ddd;
    font-size: 2rem;
    text-align: center;
    font-weight: bolder;
  }
  .text .textp {
    grid-row: 2/3;
    margin-top: -5rem;
  }
  span.hide {
    display: none;
  }
  label .text p {
    padding-bottom: 0.7rem;
    margin-left: 2rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bolder;
    text-align: left;
    font-size: 0.9rem;
    text-transform: uppercase;
    position: relative;
    display: block;
    transform: translateY(-2.5rem);
  }
  #slide1 .text p {
    transform: translateY(-4rem);
  }

  label .text p::before {
    content: '';
    display: none;
  }
  label a {
    border-radius: 4px;
    position: absolute;
    font-family: 'Roboto Mono';
    bottom: -3%;
    right: 0%;
    text-decoration: none;
    color: #fcbf1e;
    background: rgba(0, 0, 0, 0.7);
    padding: 0.5rem 1rem;
    border: 3px solid #fcbf1e;
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    margin-bottom: 1rem;
    transition: border-color 0.5s ease, color 0.5s ease;
    z-index: 1;
  }
  #spoji {
    transform: translateX(-5%);
  }

  #uppertext {
    text-align: center;
  }
  #uppertext img {
    width: 6rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -40%;
    line-height: 6rem;
  }

  #gridparent {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 1fr);
    height: 200rem;
  }
  .gbox:nth-of-type(1) {
    grid-row: 1/2;
  }
  .gbox:nth-of-type(2) {
    grid-row: 3/4;
  }
  .gbox:nth-of-type(3) {
    grid-row: 5/6;
  }
  .gbox:nth-of-type(4) {
    grid-row: 7/8;
  }

  .gbox:nth-of-type(5) {
    grid-row: 2/3;
  }
  .gbox:nth-of-type(6) {
    grid-row: 4/5;
  }
  .gbox:nth-of-type(7) {
    grid-row: 6/7;
  }
  .gbox:nth-of-type(8) {
    grid-row: 8/9;
  }
  #linijax {
    display: none;
  }

  .gbox:nth-of-type(1),
  .gbox:nth-of-type(2),
  .gbox:nth-of-type(3),
  .gbox:nth-of-type(4),
  .gbox:nth-of-type(5),
  .gbox:nth-of-type(6),
  .gbox:nth-of-type(7),
  .gbox:nth-of-type(8) {
    border-bottom: 1px solid lightgreen;
  }
  #linijay1,
  #linijay2,
  #linijay3 {
    display: none;
  }

  #uslugebody {
    grid-template-columns: 1fr;
    grid-template-rows: 4fr;
  }

  #lookaround {
    grid-template-rows: 1fr 2fr;
    grid-template-columns: 1fr;
  }
  #lookaroundtxt p {
    padding-left: 2rem;
  }
  #lookaroundtxt h3 {
    transform: translateY(-3rem);
  }
  #projects #prev {
    position: absolute;
    bottom: -5%;
    left: 5%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;

    border-radius: 4px;
    padding: 2rem 0.5rem;
    transition: background-color 0.4s ease;
  }
  #projects #next {
    padding: 2rem 0.5rem;
  }

  #upperrow {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
  }
  #footersct1 {
    grid-row: 1/2;
    justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5rem;
    transform: translateX(-2.5rem);
  }
  #footersct2 {
    grid-row: 2/3;
    display: flex;
    padding: 0 2rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }

  #footersct3 {
    grid-row: 3/4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: -2rem;
  }
  #footersct4 {
    grid-row: 4/5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #footer {
    margin: 30rem 0 0;
    display: grid;
    margin-left: -5vw;
    grid-template-rows: repeat(1fr, fit-content);
    width: 100vw;
    background-color: #212529;
    height: fit-content;
    padding-top: 7rem;
    position: relative;
  }
  #hovanim #fillbtn {
    position: absolute;
    bottom: -0.1rem;
    width: 70%;
    margin: 0 auto;
    height: 0.2rem;
    background-color: wheat;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
  }
  #footersct2 strong a {
    text-decoration: none;
    color: wheat !important;
  }
  #footersct2 strong a:hover {
    text-decoration: none;
    color: #333 !important;
  }
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .project {
    width: 77vw !important;
  }
  #uppertext h2 {
    padding: 2rem 1rem;
  }
  #projectsheader .lineh {
    margin-top: 4rem;
  }
  #lookaroundtxt h3 {
    text-align: center;
  }
  #hovanim2 {
  }
  #lookaround {
    position: relative;
    background-color: rgba(0, 0, 0, 0.3);
  }
  #lookaroundtxt {
    position: absolute;
    top: 20%;
    bottom: 0;
  }
  #projects #project {
    position: relative;
    max-width: 77vw;
    height: 35rem;

    /* background-color: rgba(0, 0, 0, 0.5); */
    /* bottom: calc(0px - (35rem / 2)); */
    top: 72vh;
    overflow: hidden;
    /* background-color: red; */
  }
  label .text {
    display: grid;
    grid-template-rows: 1fr 3fr;
  }
  label .text p {
    font-size: 1vh;
  }
  label .text h2 {
    font-size: 120%;
  }
  #smanjitxt {
    font-size: 100%;
  }
  #slide4 .text h2 span {
    color: #fff;
  }
  #mainimgcontainer {
    grid-template-columns: 1fr !important;
  }
}

@media only screen and (max-width: 1161px) and (min-width: 501px) {
  #mainimgcontainer {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
  #upperrow {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr fit-content;
  }
  #footersct1 {
    grid-column: 1/-1;
    justify-self: center;
    margin-bottom: 5rem;
    transform: translateX(-25%);
  }
  #footersct2 {
    justify-self: center;

    padding-left: 3rem;
  }
  #footersct4 {
    justify-self: end;
    padding-right: 3rem;
  }

  .slider label {
    margin: auto;
    width: 60vw;
    height: 50vh;
    border-radius: 4px;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
  }
  label .text p {
    padding-bottom: 0.5rem;
    margin-left: 2rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bolder;
    text-align: left;
    font-size: 1.3vw;
    text-transform: uppercase;
    position: relative;
  }
  label .text h2 {
    font-size: 2vw;
  }
  label .text p::before {
    content: '';
    display: none;
  }
  .hide {
    display: none;
  }
  label a {
    border-radius: 4px;
    position: absolute;
    font-family: 'Roboto Mono';
    bottom: -3%;
    right: 0%;
    text-decoration: none;
    color: #fcbf1e;
    background: rgba(0, 0, 0, 0.7);
    padding: 0.5rem 1rem;
    border: 3px solid #fcbf1e;
    font-size: 1.4vw;
    text-align: center;
    font-weight: 400;
    margin-bottom: 1rem;
    transition: border-color 0.5s ease, color 0.5s ease;
    z-index: 1;
  }
  .s1overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@media only screen and (max-width: 975px) and (min-width: 501px) {
  #uslugebody {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 2rem;
    padding-top: 5rem;
    border-top: 1px solid rgba(83, 109, 254, 0.1);
    margin-top: 2rem;
  }
  .boxtxt h2 {
    font-size: 2vw;
    align-self: center;
    color: lightgreen;
    padding-bottom: 0rem;
  }

  .boxtxt p {
    text-align: center;
    color: #fff;
    font-size: 1.6vw;
    align-self: center;
  }
  .boxtxt {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 1fr;
    padding: 1rem;
    text-align: center;
  }
}

@media only screen and (max-width: 840px) and (min-width: 501px) {
  nav ul li a {
    text-decoration: none;
    color: #1b6ca8;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding-bottom: 5px;
  }
  #liimg {
    display: none;
  }
}

@media only screen and (max-width: 768px) and (min-width: 501px) {
  .nav {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr fit-content;
  }
  .nav .homelink {
    justify-self: center;
    transform: translateX(-10%);
  }
  .nav ul {
    justify-self: center;
    margin-right: 0;
  }
  nav ul li:nth-of-type(2) {
    margin-left: 0rem !important;
  }
  nav ul li a {
    text-decoration: none;
    color: #1b6ca8;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 1.5rem;
    padding-bottom: 5px;
  }
  nav ul li:nth-of-type(2) {
    margin-left: 0rem;
  }
  .slider {
    /* transform: translateX(-100%); */
    transform: translateX(0);
    transition: transform 0.6s ease-out;
    user-select: none;
    height: 30vw;
    margin-top: 270px;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
  }
  .slider label {
    margin: auto;
    width: 60vw;
    height: 50vh;
    border-radius: 4px;
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
  }
  nav ul li {
    padding: 2.9rem 0.6rem;
    position: relative;
    transition: background-color 0.2s ease-out;
    cursor: pointer;
  }
  nav ul li:hover .underline {
    width: 80%;
  }

  #gridparent {
    position: relative;
    margin-top: 15rem;
    background-color: #fff;
    width: 100%;
    height: 400px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    height: 100rem;
  }
  .gbox:nth-of-type(1) {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .gbox:nth-of-type(2) {
    grid-row: 2/3;
  }
  .gbox:nth-of-type(3) {
    grid-row: 3/4;
  }
  .gbox:nth-of-type(4) {
    grid-row: 4/5;
  }

  .gbox:nth-of-type(5) {
    grid-row: 1/2;
    grid-column: 2/3;
  }
  .gbox:nth-of-type(6) {
    grid-row: 2/3;
    grid-column: 2/3;
  }
  .gbox:nth-of-type(7) {
    grid-row: 3/4;
    grid-column: 2/3;
  }
  .gbox:nth-of-type(8) {
    grid-row: 4/5;
    grid-column: 2/3;
  }
  #linijay1,
  #linijay3 {
    display: none;
  }
  .gbox:nth-of-type(1),
  .gbox:nth-of-type(2),
  .gbox:nth-of-type(3),
  .gbox:nth-of-type(5),
  .gbox:nth-of-type(6),
  .gbox:nth-of-type(7),
  .gbox:nth-of-type(8) {
    border-bottom: 1px solid lightgreen;
  }
  .boxtxt {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 1fr;
    padding: 1rem;
    text-align: center;
  }
  .boxtxt h2 {
    align-self: flex-end;
  }
  .project {
    width: 38vw !important;
  }
}

/* USLUGE */
.nav {
  background: rgba(0, 0, 0, 0.5);
}
#headeru {
  height: 100vh;
  width: 100vw;
  /* background-color: red; */
  display: grid;
  grid-template-rows: auto 100%;
  position: relative;
}

#slideru {
  height: 100vh;
  width: 100%;
  background-color: red;
}
#sliderux {
  height: 100%;
  width: 900vw;
  background-color: #192257;
  transform: translateX(-100vw);
  display: flex;
}
.sld {
  width: 100%;
  height: 100%;
}
.sld:first-of-type {
  background: url('/img/usluge/plan.jpeg') no-repeat center center/cover;
}
.sld:nth-of-type(2) {
  background: url('/img/usluge/ormar.jpg') no-repeat center center/cover;
}
.sld:nth-of-type(3) {
  background: url('/img/usluge/slabastrshowc1.jpg') no-repeat left center/cover;
  background-size: cover;
}
.sld:nth-of-type(4) {
  background: url('/img/usluge/Dark-Smart-Home-Background.jpeg') no-repeat left
    center/cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.sld:nth-of-type(5) {
  background: url('/img/usluge/Luxury-apartment-living-room-wallpaper-1920x1200.jpg')
    no-repeat left center/cover;
  background-size: cover;
}
.sld:nth-of-type(6) {
  background: url('/img/usluge/instruments.jpg') no-repeat left center/cover;
  background-size: cover;
}
.sld:nth-of-type(7) {
  background: url('/img/usluge/security-technology.jpg') no-repeat left center /
    cover;
  background-size: cover;
}
.sld:nth-of-type(8) {
  background: url('/img/usluge/plan.jpeg') no-repeat center center/cover;
  background-size: cover;
  /* 50% 0 on tablet mobile */
}
.sld:nth-of-type(9) {
  background: url('/img/usluge/ormar.jpg') no-repeat center center/cover;
  background-size: cover;
}
@media only screen and (max-width: 500px) {
  .slider label {
    width: 80%;
    height: 250%;
  }
  label .text p {
    font-size: 1.5vh;
  }
  label .text p {
    padding-bottom: 0.7rem;
    margin-left: 2rem;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: bolder;
    text-align: left;
    font-size: 0.9rem;
    text-transform: uppercase;
    position: relative;
    display: block;
    transform: translateY(-2.5rem);
  }
  label .text {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  label .textp {
    position: absolute;
    top: 70%;
  }
  label h2 {
    padding-top: 3rem;
  }
  .textp p {
    line-height: 2rem !important;
  }
  #footersct2 p {
    text-align: center;
  }
  #footersct3 {
    margin-left: -3rem;
  }
  #showcase {
    background: url('../../assets/img/LED_Lightings.jpg') no-repeat center
      top/cover;
  }
  .s1overlay,
  .s2overlay,
  .s3overlay,
  .s4overlay,
  .s5overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  #showcase {
    background: url('../../assets/img/LED_Lightings.jpg') no-repeat center
      top/cover;
  }
}
@media only screen and (max-width: 800px) and (max-height: 500px) {
  #showcaseb,
  #showcase,
  #header {
    height: 200vh;
    width: 100vw;
  }
  #header {
    overflow-y: hidden;
  }
  main {
    position: absolute;
    top: 200vh;
  }
  .slider label {
    margin: auto;
    width: 60vw;
    height: 100vh;
    border-radius: 4px;
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
  }
  #projects #project {
    position: relative;
    max-width: 77vw;
    height: 80vh;
    padding: 0 auto;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* bottom: calc(0px - (35rem / 2)); */
    top: 55%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    /* background-color: red; */
  }

  html,
  body {
    overflow-x: hidden;
  }
}
