#hmenu {
  visibility: hidden;
}

#nav ul {
  z-index: 200;
}
#ones {
  color: #fff;
}
#logoimg {
  width: 80px;
  transform: scale(0.8);
  z-index: 2;
}

#nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  /* background-color: #1b100b; */
  background-color: transparent;
  display: flex;
  justify-content: space-between;
}

#hambmobilemenu {
  display: none;
}
#hmenu {
  visibility: hidden;
}
#mobilemenuin,
#mobilemenuin::before,
#mobilemenuin::after {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Not implemented yet */
  -o-user-select: none;
  user-select: none;
}
#mobilemenu {
  user-select: none;
}

#fixUl {
  /* margin-top: -5rem; */
}

#logo {
  padding: 20px 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}
#ones {
  font-size: 2.8rem;
  z-index: 2;
  color: #f6d54d;
  text-transform: uppercase;
}
#twos {
  text-transform: uppercase;
  margin-left: 0.5rem;
  color: #f6d54d;
  z-index: 2;
  font-size: 2rem;
  padding-bottom: 0.2rem;
  font-weight: bolder;
}
#logoimg {
  width: 80px;
  z-index: 1;
}

#homelink {
  text-decoration: none;
}

#fixUl {
  display: flex;
  list-style-type: none;
  align-self: center;
  z-index: 0;
  position: relative;
  margin-right: 1.3rem;
  align-self: flex-start;
}
nav ul li:hover {
  background-color: #30396096;
}
nav ul li:hover .underline {
  width: 80%;
}

.underline {
  display: inline-block;
  background-color: #ffcd3c;
  width: 0%;
  height: 2px;
  z-index: 10;
  position: absolute;
  top: 70%;
  left: 10%;
  transition: width 0.5s ease;
}
nav ul li {
  padding: 2.9rem 1rem;
  position: relative;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
}

.current a {
  color: #ffcd3c !important;
}

.underline {
  background-color: #1b6ca8;
}

nav ul li a {
  text-decoration: none;
  color: #144972;
  font-weight: bolder;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 1.3rem !important;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight: 400;
  padding-bottom: 5px;
  color: #fff;
}

@media only screen and (max-width: 768px) and (min-width: 501px) {
  #fixUl li {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  #nav {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr fit-content;
    padding-top: 0.1rem;
  }
  #nav #homelink {
    justify-self: center;
  }
  #nav ul {
    justify-self: center;
    margin-right: 0;
  }
  nav ul li {
    padding: 2.9rem 0.6rem;
    position: relative;
    transition: background-color 0.2s ease-out;
    cursor: pointer;
  }
  nav ul li:nth-of-type(2) {
    margin-left: 0rem !important;
  }

  nav ul li:nth-of-type(2) {
    margin-left: 0rem;
  }
}

@media only screen and (max-width: 500px) {
  #nav {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  #logo {
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    align-items: center;
    z-index: 1;
  }
  .box:hover {
    height: 45rem;
  }
  nav #homelink {
    justify-self: center;
  }
  nav ul {
    display: none !important;
  }
  #mobilemenu {
    position: relative;
    display: inline-block;
    grid-column: 2/3;
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #mobilemenuin {
    transition: transform 0.5s ease;
    position: relative;
    display: inline-block;
    height: 0.2rem;
    width: 2.5rem;
    background-color: #fff;
    z-index: 1;
  }

  #mobilemenuin::before {
    transition: transform 0.5s ease;
    content: '';
    position: absolute;
    width: 100%;
    height: 0.2rem;
    top: 1rem;
    background-color: #fff;
    transition: all 1s ease;
  }
  #mobilemenuin::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.2rem;
    top: -1rem;
    background-color: #fff;
    transition: all 1s ease;
  }

  #hmenu:checked + #mobilemenu #mobilemenuin {
    transform: rotate(135deg);
  }

  #hmenu {
    position: absolute;
    right: 2.6rem;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 2;
    grid-column: 2/3;
    top: 2.6rem;
    opacity: 0;
    cursor: pointer;
  }
  #hmenu:checked + #mobilemenu #mobilemenuin::before,
  #hmenu:checked + #mobilemenu #mobilemenuin::after {
    top: 0;
    transform: rotate(90deg);
  }

  #hmenu:checked:hover + #mobilemenu > #mobilemenuin {
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
  }

  #hambmobilemenu {
    position: relative;
    display: block;
    transition: height 0.5s ease;
    width: 100%;
    height: 0;
    z-index: 200;
    margin-top: 0 !important;
    grid-column: 1/3;
  }
  #hambmobilemenu ul {
    width: 100%;
    height: fit-content;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
  }
  #hambmobilemenu ul li {
    padding: 1rem;
  }
  #hambmobilemenu ul li a {
    color: #fff !important;
    opacity: 1 !important;
  }

  #hmenu:checked ~ #hambmobilemenu {
    height: 22rem;
  }
  #hmenu {
    visibility: visible;
  }

  #hambmobilemenu {
    position: relative;
    display: block;
    transition: height 0.5s ease;
    width: 100%;
    height: 0;
    z-index: 200;
    margin-top: 0 !important;
    grid-column: 1/3;
  }
  #hambmobilemenu ul {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    transform: translateY(10rem);
    opacity: 0;
    transition: transform 0.5s ease 0s, opacity 0.2s ease 0s, display 1s ease 0s;
    display: none;
  }
  #hambmobilemenu ul li {
    padding: 1rem;
  }
  #hambmobilemenu ul li a {
    color: #fff !important;
    opacity: 1 !important;
  }

  #hmenu:checked ~ #hambmobilemenu {
    height: 22rem;
  }
  #nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
@media only screen and (max-width: 380px) {
  #ones {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 300px) {
  #ones {
    font-size: 1.6rem;
  }
  #logoimg {
    width: 70px;
  }
}
@media only screen and (max-width: 900px) and (max-height: 500px) {
  #logo {
    padding-top: 0.5rem;
    padding-left: 0;
  }
  #logo span {
    font-size: 2rem;
  }
  #logoimg {
    margin-top: -0.7rem;
  }
  #nav {
    margin-top: -0.1rem;
  }
}
